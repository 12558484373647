import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { ModalComponent } from "../../../components/index";
import { UserContext } from "../../../store/context/UserContext";
import "./AllprofileDropdown.scss";
import { Link } from "react-router-dom";

export default function AllProfileModal({ asLabel, asDropdown, open, onClose }) {
    const { allProfiles, userContent, populateAllProfiles, switchProfile } = useContext(UserContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProfileIndex, setSelectedProfileIndex] = useState(null);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                if ((userContent && !userContent?.superAdmin_id)) {
                populateAllProfiles();
                }
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchProfiles();
    }, []);

    const handleProfileSelection = (key) => {
        setSelectedProfileIndex(key);
    };

    const isContinueButtonDisabled = selectedProfileIndex === null;
    const switchprofileafterclick = () => {
        if (!isContinueButtonDisabled) {
            toggleModal();
            switchProfile(selectedProfileIndex);
        }
    }

    if (asLabel) {
        return (<Link to="/" className="bg-transparent btn header-item waves-effect d-flex align-items-center" >
            {userContent?.logo && <img
                src={userContent?.logo}
                alt="logo"
                // className="main-logo"
                style={{ maxWidth: "30px", maxHeight: "30px" }}
                className="me-1"
            />}
            {userContent?.companyName}


        </Link>);
    } else {
        return (
            <React.Fragment>
                {!open && <Button onClick={toggleModal} className="bg-transparent btn header-item waves-effect d-flex align-items-center"
                >
                    {userContent?.logo && <img
                        src={userContent?.logo}
                        alt="logo"
                        // className="main-logo"
                        style={{ maxWidth: "30px", maxHeight: "30px" }}
                        className="me-1"
                    />}
                    {userContent?.companyName}

                </Button>}

                <ModalComponent
                    isOpen={modalOpen || open}
                    onToggle={() => {
                        toggleModal()
                        onClose()
                    }}
                    position={"centered"}
                    size="lessmedium"
                >
                    <ModalComponent.Body>
                        <div>
                            <div>
                                <div className="main-heading">
                                    Select  Account
                                </div>
                            
                                {allProfiles?.map((profile, key) => (
                                    <div key={key} onClick={() => handleProfileSelection(key)} className={`profile-item mt-4 mb-4 ${selectedProfileIndex === key ? "selected" : ""}`}>
                                        <div className={`d-flex align-items-center justify-content-between optionWrapper ${selectedProfileIndex === key ? "selected" : ""}`} style={{ cursor: "pointer" }}>
                                            <div className="data-profile">
                                                {profile?.userContent?.logo && <img
                                                    src={profile?.userContent?.logo}
                                                    alt="logo"
                                                    className="image-profile"
                                                />}
                                                <div className={`heading-name ${selectedProfileIndex === key ? 'selected' : ''}`}>{profile?.userContent?.companyName}</div>
                                            </div>
                                            <div>
                                                {(selectedProfileIndex !== undefined && selectedProfileIndex === key) && (
                                                    <i className="uil-check d-inline-block font-size-24 color-primary"></i>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <div className="btn-continue">

                                    <Button disabled={isContinueButtonDisabled} onClick={switchprofileafterclick}
                                        style={{ backgroundColor: isContinueButtonDisabled ? 'gray' : '#3b7870' }}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </ModalComponent.Body>

                </ModalComponent>
            </React.Fragment>
        );
    }
}







