import { t } from "i18next";
import React, { useEffect, useState, useContext } from "react";
import { SelectField } from "../../SelectField/SelectField";
import { I18nContext } from "../../../store/context/i18nContext";

export default function SalutationSelect({
  innerContainerStyle,
  className,
  onChange = () => { },
  wrapperStyle,
  style,
  showClear,
  noLabel,
  outline,
  containerStyle,
  value,
  showErr,
  inline,
  borderNone,
  RightComponent,
  required,
  onClickRight = () => { },
  inputGroup,
      ...props 
  }) {
  const { t } = useContext(I18nContext);
  const data = [
    { label: t("Miss"), value: "Miss" },
    { label: t("Mr"), value: "Mr" },
    { label: t("Mrs"), value: "Mrs" },
    { label: t("Others"), value: "Others" },
  ];

  useEffect(() => {
    if (!value) {
      onChange("Mr");
    }
  }, [value])

  const doChange = (v) => {
    if (v && v?.value !== value) {
      onChange(v?.value);
    }
  }

  return (
    <React.Fragment>
      <SelectField
        required={required}
        innerContainerStyle={innerContainerStyle}
        className={className}
        onChange={doChange}
        wrapperStyle={wrapperStyle}
        style={style}
        showClear={showClear}
        data={data}
        noLabel={noLabel}
        outline={outline}
        containerStyle={containerStyle}
        value={data.find((d) => d.value === value)}
        inputGroup={inputGroup}
        label={t("Salutation")}
        error={value ? t("Please Select Salutation") : ""}
        showErr={showErr}
        inline={inline}
        borderNone={borderNone}
        RightComponent={RightComponent}
        onClickRight={onClickRight}
        {...props}
      />
    </React.Fragment>
  );
}
