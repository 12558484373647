import React from 'react'
import { InputField } from '../InputField/InputField'

function MaskInput(props) {
  const {format="DD-MM-YYYY"}=props;
 

  function formatValue(firstString, secondString) {
   
    let result = firstString; 
  
    for (let i = 0; i < firstString.length; i++) {
      if (firstString[i] === '_') { // default charachter is "_"
       result = result.slice(0, i) + secondString[i] + result.slice(i + 1);
      } 
    }
  
    return result;
  }

    return (
      <>
        <InputField
          {...props}
          onKeyDown={(event) => {
            if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && props?.numeric) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            let val = e?.target?.value ?? e;
            // val=val.replace(/-/g,"")
            const formattedValue = formatValue(val, format);
            props?.onChange(props?.datePicker ? {
              target: {
                value: formattedValue
              }
            } : formattedValue)
          }}
          mask={props.mask}
          masking={true}
        />
      </>
    )
}

export default MaskInput