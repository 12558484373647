import React from 'react'

export default function CrossButton(props) {
    return (
        // <div className="edit_cross d-none d-md-block ms-2 " onClick={() => props?.onClick()} style={{ cursor: "pointer" }}>
        //     <i style={{ fontSize: "25px" }} className="dripicons-cross" />
        // </div>
        <div className="edit_cross d-block ms-2 " onClick={() => props?.onClick()} style={{ cursor: "pointer" }}>
            <i style={{ fontSize: "25px" }} className="dripicons-cross" />
        </div>
    )
}


{/* <div className="cross-map d-block" onClick={() => MapService.toggleMap()} style={{ cursor: "pointer" }}>
                <i style={{ fontSize: "25px" }} className="dripicons-cross" />
            </div> */}
