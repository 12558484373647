import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { menuItems } from './Constant'

const Sidebar = ({ ShowSidebarMenu }) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState("");
  const initialCollapseStatus = Array(menuItems.length).fill(false);

  // Example of initialCollapseStatus:
  // [false, false, false, false, ...] - Each index corresponds to an item's collapse status

  const [collapseStatus, setCollapseStatus] = useState(initialCollapseStatus);

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    setActivePath('/' + pathSegments[1]); // Ensure we're matching the structure of paths
  }, [location]);

  const toggleCollapse = (sectionIndex, index) => {
    setCollapseStatus((prevState) => {
      // Create a new array based on the previous state
      const newState = prevState.map((item, idx) => {
        // Set the collapse status of the specified sectionIndex
        if (idx === sectionIndex) {
          return !item; // Toggle the collapse status
        } else {
          return false; // Set collapse status to false for other sections
        }
      });

      // Return the updated state
      return newState;
    });
  };


  return (
    <div className={`${ShowSidebarMenu ? "sidebar--open " : "sidebar--collapse "} mt-3 d-flex flex-column flex-shrink-0`}>
      {menuItems.map((section, sectionIndex) => (
        <div key={sectionIndex} className="mb-2">
          {section.items.map((item, itemIndex) => (
            <div className={`${activePath === item.path ? 'nav-side-active' : ''} py-2 pt-1 mx-3 mt-2  `} >
              {ShowSidebarMenu ?
                <>
                  {item?.subItems ? (
                    <div onClick={(e) => { e.stopPropagation(); toggleCollapse(sectionIndex, itemIndex); }} key={itemIndex} className="d-flex align-items-center mb-3 text-decoration-none">
                      {item?.logo({ size: 20, className: 'col-2', color: activePath === item.path ? "#fff" : "black", })}
                      {ShowSidebarMenu && <span className='' style={{ color: activePath === item.path ? "#fff" : "black", fontSize: "15px" }}>{item.name}</span>}
                      <div className="col-1 ms-auto " style={{ cursor: "pointer" }}>
                        {collapseStatus[itemIndex] ? <FaChevronDown /> : <FaChevronRight />}
                      </div>
                    </div>
                  ) :
                    <div key={itemIndex} className="d-flex align-items-center mb-1 text-decoration-none ">
                      <Link to={item.path} className={` flex-grow-1 text-decoration-none d-flex align-items-center pt-1` } activeClassName="active">
                        {item?.logo({ size: 20, className: 'col-2', color: activePath === item.path ? "#fff" : "black", })}
                        {ShowSidebarMenu && <span className='' style={{ color: activePath === item.path ? "#fff" : "black", fontSize: "15px" }}>{item.name}</span>}
                      </Link>
                    </div>}
                </> : <>
                  {item?.subItems ? (
                    <div onClick={(e) => { e.stopPropagation(); toggleCollapse(itemIndex); }} key={itemIndex} className="d-flex align-items-center mb-3 text-decoration-none">
                      {item?.logo({ size: 20, className: "col-12", color: activePath === item.path ? "#fff" : "black", })}

                    </div>
                  ) :
                    <div key={itemIndex} className="d-flex align-items-center mb-1 text-decoration-none">
                      <Link to={item.path} className={` flex-grow-1 text-decoration-none`} activeClassName="active">
                        {item?.logo({ size: 20, className: "col-12", color: activePath === item.path ? "#fff" : "black", })}
                        {ShowSidebarMenu && <span className='' style={{ color: activePath === item.path ? "#fff" : "black", fontSize: "15px" }}>{item.name}</span>}
                      </Link>
                    </div>}
                </>
              }

              {ShowSidebarMenu && item.subItems && collapseStatus[sectionIndex] && (
                <>
                  {
                    item.subItems.map((subItem, subIndex) => (
                      <span className=''>
                        <Link to={subItem.path} className={`col-10 d-flex  justify-content-center mt-4 py-2 ${activePath === subItem.path ? 'nav-sub-side-active' : ''}`} style={{ marginLeft: "auto" }} key={subIndex}>
                          <div className='col-1'></div>
                          <div className='col-2'>  {subItem.logo && (subItem?.logo({ color: activePath === subItem.path ? "#fff" : "black", size: 22, }))}</div>
                          <div className='col-9 ps-2'>
                            {ShowSidebarMenu && <span style={{ color: activePath === subItem.path ? "#fff" : "black", fontSize: "14px", text: "start" }}>{subItem.name}</span>}
                          </div>
                        </Link>
                      </span>
                    ))
                  }
                </>
              )}
            </div>
          ))}

        </div>
      ))}
    </div>
  );
};

export default Sidebar;