import React from 'react'
import { QuestionRadioGroupMultiple } from './Page6'
import { Row } from 'reactstrap'
import { CountrySelect, InputField } from '../../../../../components'

const Entity = ({ asLabel, data, validationkeyValue , handleChange, hasError, index, handleValidation }) => {
    return (
        <div>
            <QuestionRadioGroupMultiple
                field={`entities[${index}].relations`}
                question="Relationship with the applying entity."
                data={data}
                handleChange={handleChange}
                // error={hasError}
                isDisabled={asLabel}
                style={{ fontSize: 15 }}
                error={handleValidation(`entities_relations`, data?.entities?.[index].relations)}
                showErr={hasError}
                // required
                options={validationkeyValue["entities_relations"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
            />

            <Row>
                <InputField
                    className="col-sm-6"
                    label={"Name of the Entity"}
                    value={data?.entities?.[index]?.name}
                    onChange={(v) => handleChange(`entities[${index}].name`, v)}
                    error={handleValidation(`name`, data?.entities?.[index]?.name)}
                    showErr={hasError}
                    type="text"
                    // required
                    isDisabled={asLabel}
                />
                {/* <InputField
                    className="col-sm-6"
                    label={"Country of Incorporation"}
                    value={data?.entities[index]?.incorpCountry}
                    onChange={(v) => handleChange(`entities[${index}].incorpCountry`, v)}
                    error={handleValidation(`incorpCountry`, data?.entities[index]?.incorpCountry)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                /> */}
                <CountrySelect
                    label={"Country of Incorporation"}
                    className="col-sm-6"
                    value={data?.entities?.[index]?.incorpCountry}
                    onChange={(v) => handleChange(`entities[${index}].incorpCountry`, v?.value)}
                    error={handleValidation('entity_country', data?.address?.country)}
                    showErr={hasError}
                    // required
                    isDisabled={asLabel}
                />
            </Row>

            <Row>
                <InputField
                    label={"Nature of the business"}
                    value={data?.entities?.[index]?.businessNature}
                    onChange={(v) => handleChange(`entities[${index}].businessNature`, v)}
                    error={handleValidation(`businessNature`, data?.entities?.[index]?.businessNature)}
                    showErr={hasError}
                    type="text"
                    // required
                    isDisabled={asLabel}
                />
            </Row>
        </div>

    )
}

export default Entity;