import React from 'react';
import { Row } from 'reactstrap';
import { CountrySelect, InputField } from '../../../../../components';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import PercentageInput from './PercentageInput';
import { InputLabel } from './ProofOfDocument';

const Shareholder = ({  asLabel, data, handleChange, hasError, index, handleValidation, validationkeyValue }) => {
    return (
        <div>
            <InputLabel     required label={"Name"} labelStyle={{ fontSize: 15 }} />
            <Row>
                <InputField
                    className="col-sm-6"
                    placeholder="First"
                    value={data?.shareholders?.[index]?.firstName}
                    onChange={(v) => handleChange(`shareholders[${index}].firstName`, v)}
                    error={handleValidation(`userfirstName`, data?.shareholders?.[index]?.firstName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                
                />
                <InputField
                    className="col-sm-6"
                    placeholder="Last"
                    value={data?.shareholders?.[index]?.lastName}
                    onChange={(v) => handleChange(`shareholders[${index}].lastName`, v)}
                    error={handleValidation(`userlastName`, data?.shareholders?.[index]?.lastName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />
            </Row>

            
            <InputLabel required label={"Address"} labelStyle={{ fontSize: 15 }} />
            <Row>
                <InputField
                    placeholder="Building and Number"
                    // className="col-6"
                    value={data?.shareholders?.[index]?.address?.buildingNumber}
                    onChange={(v) => handleChange(`shareholders[${index}].address.buildingNumber`, v)}
                    error={handleValidation(`userBuildingNumber`, data?.shareholders?.[index]?.address?.buildingNumber)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
            </Row>
            <Row>
                <InputField
                    placeholder="Street name"
                    // className="col-6"
                    value={data?.shareholders?.[index]?.address?.street}
                    onChange={(v) => handleChange(`shareholders[${index}].address.street`, v)}
                    error={handleValidation(`userStreet`, data?.shareholders?.[index]?.address?.street)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
            </Row>
            <Row>
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="City"
                    value={data?.shareholders?.[index]?.address?.city}
                    onChange={(v) => handleChange(`shareholders[${index}].address.city`, v)}
                    error={handleValidation(`userCity`, data?.shareholders?.[index]?.address?.city)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="State / Province / Region"
                    value={data?.shareholders?.[index]?.address?.state}
                    onChange={(v) => handleChange(`shareholders[${index}].address.state`, v)}
                    error={handleValidation(`userState`, data?.shareholders?.[index]?.address?.state)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="Postal / Zip Code"
                    type="text"
                    value={data?.shareholders?.[index]?.address?.pinCode}
                    onChange={(v) => handleChange(`shareholders[${index}].address.pinCode`, v)}
                    error={handleValidation(`userPinCode`, data?.shareholders?.[index]?.address?.pinCode)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />
                <CountrySelect
                    value={data?.shareholders?.[index]?.address?.country}
                    onChange={(v) => handleChange(`shareholders[${index}].address.country`, v?.value)}
                    error={handleValidation('userCountry', data?.shareholders?.[index]?.address?.country)}
                    showErr={hasError}
                    noLabel
                    isoCode
                    placeholder={"Select Country"}
                    className="col-sm-6 col-md-3"
                    isDisabled={asLabel}
                />
            </Row>

            <Row>
                {/* <PhoneNum
                    className='col-sm-6 mt-2'
                    label="Phone"
                    value={{
                        phone: data?.shareholders?.[index]?.phone,
                        countryCode: data?.shareholders?.[index]?.address?.countryCode,
                    }}
                    onChange={(v) => {
                        handleChange(`shareholders[${index}].phone`, v?.phone)
                        handleChange(`shareholders[${index}].countryCode`, v?.countryCode)
                    }}
                    //error={handleValidation(`phone`, data?.shareholders?.[index]?.phone)}
                    // showErr={hasError}
                    isDisabled={asLabel}
                /> */}
                <InputField
                    className="col-sm-6"
                    label={"Phone Number"}
                    type="number"
                    value={data?.shareholders?.[index]?.phone}
                    onChange={(v) => handleChange(`shareholders[${index}].phone`, v)}
                    error={handleValidation(`userphone`, data?.shareholders?.[index]?.phone)}
                    showErr={hasError}
                    required
                    isDisabled={asLabel}
                />
                <InputField
                    className="col-sm-6"
                    label={"Email"}
                    type="email"
                    value={data?.shareholders?.[index]?.email}
                    onChange={(v) => handleChange(`shareholders[${index}].email`, v)}
                    error={handleValidation(`useremail`, data?.shareholders?.[index]?.email)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required
                />
            </Row>

            <Row>
                {/* <PhoneNum
                    className='col-sm-6 mt-2'
                    label="Mobile Phone"
                    value={{
                        phone: data?.shareholders?.[index]?.mobilePhone,
                        countryCode: data?.shareholders?.[index]?.address?.countryCode,
                    }}
                    onChange={(v) => {
                        handleChange(`shareholders[${index}].mobilePhone`, v?.phone)
                        handleChange(`shareholders[${index}].countryCode`, v?.countryCode)
                    }}
                    //error={handleValidation(`mobilePhone`, data?.shareholders?.[index]?.mobilePhone)}
                    // showErr={hasError}
                    isDisabled={asLabel}
                /> */}
                <InputField
                    className="col-sm-6"
                    label={"Mobile Phone"}
                    type="number"
                    value={data?.shareholders?.[index]?.mobilePhone}
                    onChange={(v) => handleChange(`shareholders[${index}].mobilePhone`, v)}
                    error={handleValidation(`usermobilePhone`, data?.shareholders?.[index]?.mobilePhone)}
                    showErr={hasError}
                    required
                    isDisabled={asLabel}
                />
                {/* <AntDateSelect
                    placeholder={"dd/mm/yyyy"}
                    value={data?.shareholders?.[index]?.doa}
                    onChange={(v) => handleChange(`shareholders[${index}].doa`, v)}
                    //error={handleValidation('doa', data?.shareholders?.[index]?.doa)}
                    // showErr={hasError}
                    className="col-sm-6"
                    label="Date of Application"
                    isDisabled={asLabel}
                /> */}
            </Row>

            <Row>
                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="Source(s) of Wealth"
                    data={validationkeyValue["sourceOfWealth"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                    placeholder="Select"
                    value={data?.shareholders?.[index]?.sourceOfWealthList ? data?.shareholders?.[index]?.sourceOfWealthList : []}
                    onChange={(v) => {
                        // console.log(v);
                        handleChange(`shareholders[${index}].sourceOfWealthList`, v)
                    }}
                    error={handleValidation('sourceOfWealth', data?.shareholders?.[index]?.sourceOfWealthList )}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required

                />
         
            </Row>

            <Row>
                <InputLabel  label={"Nationality"} labelStyle={{ fontSize: 15 }} required />

                <CountrySelect
                    placeholder={"Select Nationality"}
                    className={""}
                    isDisabled={asLabel}
                    noLabel
                    isoCode
                    value={data?.shareholders?.[index]?.nationality}
                    onChange={(v) => handleChange(`shareholders[${index}].nationality`, v?.value)}
                    error={handleValidation('shareholderCountry', data?.shareholders?.[index]?.nationality)}
                    showErr={hasError}
                />
            </Row>

            <Row>
                <PercentageInput
                    className="col-sm-6"
                    label={"Percentage of Ownership"}
                    value={data?.shareholders?.[index]?.percentOwner?data?.shareholders?.[index]?.percentOwner:""}
                    onChange={(e) => {
                            handleChange(`shareholders[${index}].percentOwner`, e);
                    }}
                    isDisabled={asLabel}
                    error={handleValidation(`percentOwner`, data?.shareholders?.[index]?.percentOwner)}
                    showErr={hasError}
                    required
                />
                <InputField
                    className="col-sm-6"
                    label={"TAX ID or Passport Number"}
                    value={data?.shareholders?.[index]?.taxId}
                    onChange={(v) => handleChange(`shareholders[${index}].taxId`, v)}
                    error={handleValidation(`taxId`, data?.shareholders?.[index]?.taxId)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    required
                />
            </Row>
        </div>
    )
}

export default Shareholder;