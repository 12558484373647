import React, { useContext, useEffect, useState } from 'react';
import { CardThreeBounce, Documents, InputField, SelectField } from '../../../components';
import SubmitButton from '../../../components/Buttons/SubmitButton';
import { doGET, doPUT } from '../../../util/HttpUtil';
import { InputLabel } from './components/IndividualLite/UserDetailsForm';
import { DialogContext } from '../../../store/context/DialogContext';

export const FormEndpoints = {
    INDIVIDUAL_LITE: "individual-lite",
    BUSINESS_LITE: "business-lite",
    INDIVIDUAL_PLUS: "individual-lite",
    BUSINESS_PLUS: "business-lite",
    INIDIVIDUAL_PRIME: "individual-prime",
    BUSINESS_PRIME: "business-prime"
}


const ExtraQuestions = ({ dataLoading, formType, data, asLabel, questionIds = [], formId, onSubmit }) => {
    const [extraQuestions, setExtraQuestions] = useState([]);
    const [responses, setResponses] = useState({});
    const [loading, setLoading] = useState(false);
    const [applicationData, setApplicationData] = useState(data);
    const { showError } = useContext(DialogContext)

    useEffect(() => {
        if (Object.keys(data ?? {})?.length) {
            setApplicationData(data)
        }
    }, [JSON?.stringify(data)]);


    useEffect(() => {
        setLoading(dataLoading)
    }, [dataLoading])

    // Update questions and responses based on cannedQues
    const updateQuesAndResponses = (cannedQues) => {
        const responsesMap = applicationData.asks.reduce((acc, curr) => {
            acc[curr.question_id ?? curr.question] = curr;
            return acc;
        }, {});

        const adhoc = applicationData.asks?.filter((v) => !v?.question_id)?.map((v) => { return { title: v?.question, inputType: "TEXT" } });
        setExtraQuestions([...(cannedQues ?? []), ...(adhoc ?? [])]);
        setResponses(responsesMap);
    }

    // Fetch additional questions and responses
    useEffect(() => {
        const fetchExtraQuestionsAndResponses = async () => {
            setLoading(true);
            try {
                if (questionIds.length > 0) {
                    let questionsQuery = questionIds.join(',');
                    const cannedQuesResponse = await doGET(`/api/verification-ask/grid?_id=in[${questionsQuery}]`);
                    if (cannedQuesResponse?.status === 200) {
                        updateQuesAndResponses(cannedQuesResponse.data.rows ?? [])
                    } 
                } else {
                    updateQuesAndResponses([])
                }
            } catch (err) {
                showError('An error occurred while fetching the questions.');
            } finally {
                setLoading(false);
            }
        };


        fetchExtraQuestionsAndResponses();

    }, [questionIds]);

    // Handle response changes for individual questions
    const handleResponseChange = (questionId, value) => {
        setResponses(prevResponses => ({
            ...prevResponses,
            [questionId]: { ...(responses[questionId]), response: value },
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const localArray = [];

        try {
            // Validate and collect responses
            for (const [questionId, response] of Object.entries(responses)) {
                if (response?.response) {
                    localArray.push(response);
                } else {
                    showError("Please Fill All Fields or reupload the documents");
                    return;
                }
            }
            const updateResponse = await doPUT(`/api/${FormEndpoints[formType]}/update`, { ...applicationData, status: 1, asks: localArray });
            if (updateResponse?.status == 200) {
                // Mark form as submitted
                await doGET(`/api/${FormEndpoints[formType]}/submit?id=${formId}&markFormSubmit=true`)
            }
            onSubmit();
        } catch (err) {
            showError(err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <CardThreeBounce />;


    return (
        <div className='pb-3' >
            {
                extraQuestions?.length > 0 ?

                    <> {extraQuestions.map((question) => (
                        <div key={question._id}>

                            {question.inputType === 'TEXT' && (
                                <InputField
                                    label={question.title}
                                    isDisabled = {asLabel}
                                    placeholder={`Enter ${question.title}`}
                                    value={responses[question._id ?? question.title]?.response || ''}
                                    onChange={(value) => handleResponseChange(question._id ?? question.title, value)}
                                />
                            )}
                            {question.inputType === 'DOCUMENT' && (
                                <>
                                    <InputLabel label={question.title} required />
                                    <Documents
                                        asLabel={asLabel}
                                        inline
                                        module={formType}
                                        showExpiry
                                        field={question.title}
                                        record_id={applicationData?._id}
                                        onChange={(value) => {
                                            handleResponseChange(question._id ?? question.title, JSON?.stringify(value?.attachment))
                                        }}
                                    />

                                </>

                            )}

                            {question.inputType === 'DROPDOWN' && (
                                <SelectField
                                    label={question.title}
                                    isDisabled={asLabel}
                                    data={question.options.map(option => ({ label: option, value: option }))}
                                    value={responses[question._id]?.response || ''}
                                    onChange={(selected) => handleResponseChange(question._id, selected?.value)}
                                />
                            )}
                        </div>
                    ))}</> : <> Extra Information Asked from user will appear here</>}


            {!asLabel && <div className='d-flex w-100 justify-content-betweem mt-3'>
                <div className='flex-1'>
                </div>
                <SubmitButton
                    loading={loading}
                    onClick={(e) => {

                        handleSubmit(e);
                    }}
                />

            </div>}

        </div>
    );
};

export default ExtraQuestions;
