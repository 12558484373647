import React,{useState,useEffect} from 'react';
import { FaRegUserCircle, FaDollarSign, FaMobileAlt, FaRegListAlt, FaTools } from 'react-icons/fa';
import Logo from "../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
    const [imgHeight, setImgHeight] = useState(150);
    const  navigate  = useNavigate()
    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth <= 600) {
            setImgHeight(60);
          } else {
            setImgHeight(60); 
          }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    return (
      <div className="select-screen">
        <div className="fv_start_screen-container">
        <div className="">

            <div className='d-flex flex-column  justify-content-center  align-items-center  '>
                {<img
                    src={Logo}
                    alt="fv-bank"
                    height={imgHeight}
                    style={{ marginBottom: "40px" }}
                    className="logo logo-dark"
                />}
            </div>


            <div className=' '>
               <h1>
                Coming Soon !
               </h1>
               <br/>
              <h3>
                Something is cooking...
              </h3>
            </div>

          </div>
        </div>
      </div>
    );
};

export default ComingSoon;
