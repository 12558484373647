import React, { useState, useEffect } from 'react';
import './SelectScreen.scss';
import Logo from "../../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const SelectScreen = () => {
    const [imgHeight, setImgHeight] = useState(65);
    const navigate = useNavigate();

    useEffect(() => {
        const unauthUrl = localStorage.getItem('unauthUrl') ?? "individual-plus"
        navigate(`/${unauthUrl}`)
        // const handleResize = () => {
        //     setImgHeight(window.innerWidth <= 600 ? 50 : 65);
        // };

        // handleResize();

        // window.addEventListener('resize', handleResize);

        // return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="select-screen">
            <div className="fv_start_screen-container">
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                        src={Logo}
                        alt="fv-logo"
                        height={imgHeight}
                        className="fv_start_screen-logo"
                    />
                </div>
                <h1 className='fv_start_screen-title'>
                    Choose Your Account Type
                </h1>
                <p className='fv_start_screen-subtitle'>
                    Select the type of account you want to apply for and get started with our simple, secure, and convenient online banking.
                </p>
                <div className='fv_start_screen-card-container'>
                    <Card className="fv_start_screen-card fv_start_screen-responsive-card">
                        <h2>Simple, Secure, and Convenient Online Banking for Modern Individuals</h2>
                        <div className="d-flex justify-content-center fv_start_screen-card-button">
                            <button
                                className="fv_start_screen-button"
                                onClick={() => navigate("/individual-plus")}
                            >
                                Apply Individual Plus
                            </button>
                        </div>
                    </Card>
                    <Card className="fv_start_screen-card fv_start_screen-responsive-card">
                        <h2>Empowering Your Business with Comprehensive Banking Solutions</h2>
                        <div className="d-flex justify-content-center fv_start_screen-card-button">
                            <button
                                className="fv_start_screen-button"
                                onClick={() => navigate("/business-plus")}
                            >
                                Apply Business Plus
                            </button>
                        </div>
                    </Card>
                    <Card className="fv_start_screen-card fv_start_screen-responsive-card">
                        <h2>Exclusive Benefits and Premium Services for Business</h2>
                        <div className="d-flex justify-content-center fv_start_screen-card-button">
                            <button
                                className="fv_start_screen-button"
                                onClick={() => navigate("/business-prime")}
                            >
                                Apply Business Prime
                            </button>
                        </div>
                    </Card>
                    <Card className="fv_start_screen-card fv_start_screen-responsive-card">
                        <h2>Premium Banking Solutions Tailored for Individuals</h2>
                        <div className="d-flex justify-content-center fv_start_screen-card-button">
                            <button
                                className="fv_start_screen-button"
                                onClick={() => navigate("/pages-comingsoon")}
                            >
                                Apply Individual Prime
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default SelectScreen;
