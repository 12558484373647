import React, { useEffect, useRef, useCallback, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, FormGroup, Label } from 'reactstrap';
import "./style.css"

function SignPad({ asLabel, value = '', style = {}, onChange = () => { } }) {
    const sigCanvas = useRef(null);
    const fileInputRef = useRef(null);
    const [inputKey, setInputKey] = useState(Date.now());

    const clear = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
            onChange('');
        }
        setInputKey(Date.now());
    };

    const updateCanvas = useCallback(() => {
        if (value && sigCanvas.current && sigCanvas.current.isEmpty()) {
            sigCanvas.current.fromDataURL(value);
        }
    }, [value]);

    useEffect(() => {
        updateCanvas();
    }, [updateCanvas]);

    const handleEnd = () => {
        if (!asLabel && sigCanvas.current && !sigCanvas.current.isEmpty()) {
            const dataUrl = sigCanvas.current.toDataURL('image/png');
            onChange(dataUrl);
        }
    };

    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                onChange(reader.result);
            };
            reader.readAsDataURL(file);
            clear();
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <div className='signPad'>
            <div className="bg-white rounded-2 mb-2 inner-sign-pad">
                <SignatureCanvas
                    penColor={asLabel ? "transparent" : "black"}
                    ref={sigCanvas}
                    canvasProps={{
                        className: 'sigCanvas canvas',
                        backgroundColor: '#FFF',
                        ...style
                    }}
                    onEnd={handleEnd}
                />
            </div>
            {!asLabel && (
                <>
                    <FormGroup className="my-2">
                        <Label for="uploadSign">Upload Signature</Label>
                        <input
                            key={inputKey}
                            ref={fileInputRef}
                            type="file"
                            id="uploadSign"
                            accept="image/*"
                            onChange={handleUpload}
                            style={{ display: 'none' }} // Hide the actual input
                        />
                        <Button className="w-100" onClick={triggerFileInput}>
                            Choose File
                        </Button>
                    </FormGroup>
                    <Button outline className="w-100 my-2 mt-0 font-size-14 py-1" onClick={clear}>
                        Clear
                    </Button>
                </>
            )}
        </div>
    );
}

export default SignPad;
