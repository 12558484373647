import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from "react"
import { connect } from "react-redux"

import { Link, useLocation } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

//i18n
import { withTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { HiBellAlert } from "react-icons/hi2";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import ToggleSidebar from '../../assets/images/icons/ToggleSidebar.svg';
import BellIcon from '../../assets/images/header/BellIcon.svg';
import { UserContext } from '../../store/context/UserContext'
import { menuItems } from "./Constant"


// import { noOptionsMessageCSS } from 'react-select/dist/declarations/src/components/Menu'

const Header = props => {
  const [isSearch, setSearch] = useState(false)
  const { userContent, currentPageName, setCurrentPageName } = useContext(UserContext);
  const [isTicketOpen, setIsTicketOpen] = useState(false)
  const [activeLink, setActiveLink] = useState("");
  let navigate = useNavigate();
  const location = useLocation();



  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    const currentSection = menuItems.find(section =>
      section.items.some(item => {
        // Check item path
        if (item?.path === `/${pathSegments[1]}`) {
          setCurrentPageName(item.name);
          return true;
        }

        // If item path not found, check subItems
        if (item?.subItems) {
          const subItem = item.subItems.find(subItem =>
            subItem?.path === `/${pathSegments[1]}`
          );
          if (subItem) {
            setCurrentPageName(subItem.name);
            return true;
          }
        }

        return false;
      })
    );
  }, [location.pathname, menuItems]);


  useEffect(() => {
    setActiveLink(window.location.pathname.split('/')[1])
  }, [window.location.pathname])
  return (
    <React.Fragment>
      <div className="navbar-header d-flex justify-content-between align-items-center mx-0">
        <div className='d-flex col-4'>
          <div className='col-1 px-2 d-flex align-items-center'>
            {<img src={ToggleSidebar} onClick={props?.ToggleSidebarMenu}
              style={{ cursor: "pointer", }} />}
          </div>
          <div className='col-10 ms-2 d-flex align-items-center' style={{ fontSize: "18px", color: "black" }}>
            <b>{currentPageName}</b>
          </div>
        </div>
        <div className='d-flex col-3 me-0' style={{ marginTop: '2px', marginLeft: "auto" }}>
          <div className='d-flex  align-items-center' style={{ marginLeft: "auto", }}>
            {<img src={BellIcon} style={{ cursor: "pointer", height: "54px", width: "54px", marginLeft: "20px" }} />}
            <ProfileMenu />
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
