import React, { useContext, useEffect, useState } from "react";
import LoginCredentials from "./LoginCredentials";
import LoginOtp from "./LoginOtp";
import { DialogContext } from "../../store/context/DialogContext";
import { doGET, doPOST } from "../../util/HttpUtil";
import { useNavigate, useLocation } from "react-router-dom";
import { ENDPOINTS } from "../../common/utils/Constants";

export function validateEmail(email) {
  const re = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return re.test(email);
}

const Auth = ({ isUser, form }) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [data, setData] = useState({
    authType: "otp",
    phone: "",
    language: "en",
    captchaQuery: "",
  });

  const [loading, setLoading] = useState(false);
  const { showError, showMessage } = useContext(DialogContext);
  const [step, setStep] = useState(currentRoute === "/sign-up" ? 2 : 0);

  const [captcha, setCapcha] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [otpId, setOtpId] = useState("");
  const [ubo, setUbo] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    phone: "",
    captchaQuery: "",
  });
  const [imageURL, setImageUrl] = useState();
  const [selecteToggle, setSelectedToggle] = useState(true);
  const [hasErr, setHasErr] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const formId = localStorage.getItem('form_id');
    const email = localStorage.getItem('email');
    const uboId = localStorage.getItem('ubo_id');
    const unauthUrl = localStorage.getItem('unauthUrl');

    localStorage.clear();

    if (unauthUrl) localStorage.setItem('unauthUrl', unauthUrl);
    if (formId) localStorage.setItem('form_id', formId);
    if (email) localStorage.setItem('email', email);
    if (uboId) localStorage.setItem('ubo_id', uboId);
  }, []);

  const getCaptcha = async () => {
    const dta = {
      for: "captcha",
    };
    try {
      setCaptchaLoading(true);
      let res = await doPOST(ENDPOINTS.LoginGetCaptcha, dta);
      setImageUrl(res.data.image);
      setCapcha(res.data.captcha_id);
    } catch (error) {
      showError(error);
    } finally {
      setCaptchaLoading(false);
    }

  };


  useEffect(() => {

    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const utm_referer = urlObject.searchParams.get('utm_referer');
   
    if (form?.type == "kyc") {
      
      let emailParam = decodeURIComponent(urlObject.searchParams.get('email'));
      if (emailParam) {
        emailParam = emailParam.replace(/ /g, '+');
      }
      const ubo_id = urlObject.searchParams.get('ubo_id');
      const application_id = urlObject.searchParams.get('form_id');
      if (emailParam) {
        localStorage.setItem('email', emailParam);
      }
      if (ubo_id) {
        localStorage.setItem('ubo_id', ubo_id);
      }

      if (application_id) {
        localStorage.setItem('form_id', application_id);
      }


      setData({
        ...ubo,
        ...(utm_referer && { utm_referer }),
        email: emailParam,
        ubo_id: ubo_id,
        application_id: application_id
      })
    } else {
      setData(prevData => ({
        ...prevData,
        ...(utm_referer && { utm_referer })
      }));
    }

  }, []);


  const getOTP = async () => {
    if (!validateEmail(data?.email)) {
      setHasErr(true)
      showError("Please provide valid email");
      return;
    }
    if (!data?.captchaQuery) {
      setErrorMessage({
        ...errorMessage,
        captchaQuery: "Captcha is required",
      });
      return;
    }

    const newData = {
      ...(data?.phone && !selecteToggle && { phone: data?.phone }),
      ...(data?.phone && !selecteToggle && { countryCode: data?.countryCode ? data?.countryCode : "91" }),
      ...(data?.email && selecteToggle && { email: data?.email }),
      platform: "web",
      captchaOTP_Id: captcha,
      captcha: data?.captchaQuery,
      //   captchaQuery: ,
      //   captcha_id: captcha,
      //   otp_id: otp_id
    };
    try {
      setLoading(true);
      const res = await doPOST("/auth/token", newData);
      if (res?.status === 200) {
        showMessage("OTP SENT SUCCESSFULLY", "Success");
        setOtpId(data?.email ? res?.data?.email : res?.data?.phone);
        setStep(1);
        setHasErr(false)
      }
    } catch (error) {
      setHasErr(true)
      getCaptcha()
      setErrorStatus(error);
      setData((prev) => ({
        ...prev,
        captchaQuery: "",
      }));
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const isInspecting = typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object";

  return (
    <>
      <div
        className="d-flex justify-content-center row"
        style={{
          margin: "auto",
          height: isInspecting ? "100vh" : "auto",
        }}
      >
        <div className=" col-12 ">
          {step === 0 ? (
            <LoginCredentials
              errorMessage={errorMessage}
              getOTP={getOTP}
              data={data}
              setData={setData}
              hasErr={hasErr}
              loading={loading}
              setLoading={setLoading}
              captcha={captcha}
              setCapcha={setCapcha}
              onClickSignUp={() => navigate("/sign-up")}
              getCaptcha={getCaptcha}
              imageURL={imageURL}
              isUser={isUser}
              form={form}
              setSelectedToggle={setSelectedToggle}
              selecteToggle={selecteToggle}
              captchaLoading={captchaLoading}
              setCaptchaLoading={setCaptchaLoading}
            />
          ) : step === 1 ? (
            <LoginOtp
              errorStatus={errorStatus}
              setErrorStatus={setErrorStatus}
              otpId={otpId}
              data={data}
              setData={setData}
              setStep={setStep}
              getOTP={getOTP}
              isUser={isUser}
              form={form}
              captcha={captcha}
              setCapcha={setCapcha}
              loading={loading}
              setLoading={setLoading}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Auth;
