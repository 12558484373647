import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';
import { DialogContext } from '../../store/context/DialogContext';
import { GrDocumentPdf } from 'react-icons/gr';
import { FaSpinner } from 'react-icons/fa';

const PDFLabel = ({ src, placeholder = "", style, ...restProps }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false); // State for modal visibility
    const { showError } = useContext(DialogContext);

    const fetchPDF = async (src) => {
        setLoading(true);
        try {
            const response = await axios({
                url: src,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    Authorization: localStorage.getItem("BearerToken"),
                },
                withCredentials: true
            });
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            setPdfUrl(url);
        } catch (error) {
            console.error("Error fetching PDF:", error);
            showError("Failed to load PDF");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (src && modal && (src.slice(1, 4) === "api")) {
            fetchPDF(src);
        }
    }, [src, modal]);

    const toggleModal = () => setModal(!modal);

    if (loading) return <div style={{ width: "60px", height: "60px", cursor: "pointer" }}  className="fv_ondato_rule d-flex justify-content-center align-items-center">
        <FaSpinner size={14} className="spinner" />
    </div>


    return (
        <div style={style}>
            {pdfUrl ? (
                <>
                    <div className='d-flex justify-content-center align-items-center' style={{ width: "60px", height: "60px", cursor: "pointer" }}>
                        <GrDocumentPdf onClick={() => { toggleModal() }} size={40} />
                    </div>

                    <Modal size="xl" isOpen={modal} toggle={toggleModal} centered>
                        <ModalBody>
                            <object 
                                // data={`${pdfUrl}#toolbar=0`}
                                data={`${pdfUrl}`}  
                            type="application/pdf" style={{ width: '100%', height: '80vh' }}
                                {...restProps}>
                                <p>Your browser does not support PDFs. <a href={pdfUrl} target="_blank" rel="noopener noreferrer">Download the PDF</a>.</p>
                            </object>
                        </ModalBody>
                    </Modal>
                </>
            ) : (
                <>

                    <div className='d-flex justify-content-center align-items-center' style={{ width: "60px", height: "60px", cursor: "pointer" }}>
                        <GrDocumentPdf onClick={() => { if (src) toggleModal() }} size={40} />
                    </div>

                    <Modal size="xl" isOpen={modal} toggle={toggleModal} centered>
                        <ModalBody>
                                <object 
                                // data={`${src}#toolbar=0`}
                                data={`${src}`}
                                 type="application/pdf" style={{ width: '100%', height: '80vh' }}
                                {...restProps}>
                                <p>Your browser does not support PDFs. <a href={pdfUrl} target="_blank" rel="noopener noreferrer">Download the PDF</a>.</p>
                            </object>
                        </ModalBody>
                    </Modal></>
            )}
        </div>
    );
};

export default PDFLabel;
