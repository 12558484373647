import { makeAutoObservable } from "mobx";
import { doGET, doPOST, doPUT, doDELETE } from "../../util/HttpUtil";

const ENDPOINTS = {
  grid: () => `/api/doc-category/grid?rows=-1`,
  get: (id) => `/api/doc-category/detail?id=${id}`,
  save: `/api/doc-category/create`,
  update: `/api/doc-category/update`,
  delete: (id) => `/api/doc-category/delete?id=${id}`,
};

class DocCategoryService {
  records = [];
  selectedCategory = {};
  originalData = [];
  loading = false;
  fetchQueue = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedCategory = (category) => {
    this.selectedCategory = category;
  };

  fetchSingleton = async () => {
    if (this.loading) {
      return new Promise((resolve, reject) => {
        this.fetchQueue.push({ resolve, reject });
      });
    }

    if (this.originalData.length > 0) {
      return this.records;
    }

    this.loading = true;

    try {
      const response = await doGET(ENDPOINTS.grid());
      if (response?.status === 200 && Array.isArray(response.data.rows)) {
        this.records = this.buildHierarchy(response.data.rows);
        this.originalData = response.data.rows;
        this.loading = false;
        this.fetchQueue.forEach(({ resolve }) => resolve(this.records));
        this.fetchQueue = [];
        return this.records;
      } else {
        throw new Error("Failed to fetch document categories");
      }
    } catch (error) {
      this.loading = false;
      this.fetchQueue.forEach(({ reject }) => reject(error));
      this.fetchQueue = [];
      throw error;
    }

  };


  fetch = async () => {
    const response = await doGET(ENDPOINTS.grid());
    if (response?.status === 200 && Array.isArray(response.data.rows)) {
      this.records = this.buildHierarchy(response.data.rows);
      this.originalData = response.data.rows;
      return this.records;
    }
  };

  buildHierarchy = (categories) => {
    let hierarchy = [];
    let lookup = {};

    categories.forEach(category => {
      lookup[category._id] = { ...category, children: [] };
    });

    categories.forEach(category => {
      if (category.parent_id && lookup[category.parent_id]) {
        lookup[category.parent_id].children.push(lookup[category._id]);
      } else {
        hierarchy.push(lookup[category._id]);
      }
    });

    return hierarchy;
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    if (response.status === 200) {
      this.fetch();
      return response.data;
    }
  };

  update = async (data) => {
    const response = await doPUT(ENDPOINTS.update, data);
    if (response.status === 200) {
      this.fetch();
    }
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  transformData = (originalData) => {
    const map = new Map();
    const result = [];


    for (const item of originalData) {
      if (item.parent_id) {
        const parent = map.get(item.parent_id) || [];
        parent.push({ ...item, value: item._id, title: item.name });
        map.set(item.parent_id, parent);
      }
    }


    for (const item of originalData) {
      if (!item.parent_id) {
        result.push({
          value: item.name,
          title: item.name,
          children: map.get(item._id) || [],
        });
      }
    }

    return result;
  }
}

export default new DocCategoryService();
