export function isArrayNonEmpty(param) {
  return Array.isArray(param) && param.length > 0;
}
export function arrayToCommaSeperatedString(param) {
  if (!isArrayNonEmpty(param)) {
    return param;
  }
  return param.join(', ');
}
export function processApiResponseForFile(response, defaultFileName) {
  const contentDisposition = response.headers.get('Content-Disposition');
  let filename = defaultFileName;
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  const blob = response?.data;
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
export function assignValueToArray(array, index, value) {
  if (array === null) {
    array = [];
  }
  while (array.length <= index) {
    array.push({});
  }
  array[index] = value;
}

export const multiplyBy10ToPower = (value, power = 2) => {
  return value * Math.pow(10, power);
};

export function handleShowIfCondition(data, showIf) {
  const { field, condition, value, required } = showIf;

  const fieldParts = field.split('.');

  let fieldValue = data;
  for (let part of fieldParts) {
    if (fieldValue === undefined || fieldValue === null) {
      return false;
    }
    fieldValue = fieldValue[part];
  }
  if (required) {
    switch (condition) {
      case '==':
        return fieldValue == value;
      case '!=':
        return fieldValue != value;
      case '>':
        return fieldValue > value;
      case '<':
        return fieldValue < value;
      case '>=':
        return fieldValue >= value;
      case '<=':
        return fieldValue <= value;
      case '===':
        return fieldValue === value;
      case '!==':
        return fieldValue !== value;
      default:
        return false;
    }
  } return true

}

export const getOrdinalSuffix = (index) => {
  const j = index % 10,
    k = index % 100;
  if (j === 1 && k !== 11) {
    return `${index}st`;
  }
  if (j === 2 && k !== 12) {
    return `${index}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${index}rd`;
  }
  return `${index}th`;
};

