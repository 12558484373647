import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Col, Row, Button, Modal } from "reactstrap";
import { formatBytes } from "..";
import { Image } from "..";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doDELETE } from "../../util/HttpUtil";
import PDFLabel from "../Image/PDFLabel";
import "./styles.scss"

function getFilenameFromPath(path) {
  const parts = path.split('/');
  return parts[parts.length - 1];
}

const truncateFilename = (filename) => {
  if (filename.length > 20) {
    const start = _.slice(filename, 0, 5).join('');
    const end = _.slice(filename, -7).join('');
    return `${start}.....${end}`;
  }
  return filename;
};

const DropZoneOnline = ({ 
  asLabel, 
  inline, 
  value, 
  onChange = () => { }, 
  single, 
  supportedFiles = ["image", "pdf"], 
  fileSizeText, 
  fileSize, 
  onDeleteAttachment = () => { }, 
  onDelete, 
  module, 
  field, 
  id 
}) => {
  const [selectedFiles, setSelectedFiles] = useState(value ? value : []);
  const { showConfirm, showMessage, showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [modal, setModal] = useState(false);
  const [iframeModal, setIframeModal] = useState(false);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        key: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    if (single) {
      setSelectedFiles([files[0]]);
      onChange([files[0]]);
    } else {
      setSelectedFiles([...selectedFiles, ...files]);
      onChange([...selectedFiles, ...files]);
    }
  }

  useEffect(() => {
    setSelectedFiles(value ? value : []);
  }, [value]);

  const handleDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete file?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        const data = {
          _id: id,
          field: field,
          module: module,
        }
        const response = await doDELETE("/api/file/delete/single", data);
        if (response.status === 200) {
          showMessage("Deleted", "Deleted");
          onDelete();
        }
      } catch (e) {
        showError(e);
      }
    }
  };

  return (
    <Row style={{ width: "100%" }}>
      <div className={`${inline ? " d-flex flex-wrap p-0 " : ""}`}>
        {(!single || selectedFiles && selectedFiles.length == 0 ||
          !selectedFiles[0].path) && selectedFiles.map((f, i) => {
            return (
              <div
                className="mt-1 mb-0 shadow-none px-0 dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div
                  className={`${inline ? "pb-2 px-2" : "p-2"}`}
                >
                  <div style={{ position: "relative" }} className="d-flex align-items-center justify-content-between">
                    <FileUploadRowItem hideIndexes file={f} index={i} />
                    {!asLabel && (
                      <div
                        style={{ position: "absolute", right: "-8px", top: "-8px" }}
                        className=" d-flex align-items-end justify-content-end cursor-pointer"
                        onClick={(index) => {
                          if (f?.size) {
                            const updatedSelectedFiles = [...selectedFiles];
                            updatedSelectedFiles.splice(i, 1);
                            setSelectedFiles(updatedSelectedFiles);
                          } else if (typeof f === 'string') {
                            handleDelete();
                          } else if (f?.key?.slice(1, 13) === "api/o/assets") {
                            onDeleteAttachment(i).then((deleted) => {
                              if (deleted) {
                                const updatedSelectedFiles = [...selectedFiles];
                                updatedSelectedFiles.splice(index, 1);
                                setSelectedFiles(updatedSelectedFiles);
                              }
                            });
                          }
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            border: "2px solid red",
                            borderRadius: "20px",
                            color: "red",
                          }}
                          className="dripicons-cross text-red"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {!asLabel && (
        <div>
          <Button color="primary" onClick={() => setModal(true)}>
            Add Document
          </Button>
          <Modal isOpen={modal} toggle={() => setModal(false)} size="lg" centered>
            <div className="modal-header">
              <h5 className="modal-title">Select Upload Method</h5>
              <button type="button" className="close" onClick={() => setModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex justify-content-between">
              <div className="col-sm-6 text-left upload-option d-flex flex-column">
                <h6 className="upload-title text-primary">Download Online</h6>

                <ul className="list-unstyled mb-3 flex-grow-1">
                  <li className="mb-3 d-flex align-items-center">
                    <div className="icon-wrapper"><i className="fas fa-bolt"></i></div>
                    <span>Faster Account Creation and Activation</span>
                  </li>
                  <li className="mb-3 d-flex align-items-center">
                    <div className="icon-wrapper"><i className="fas fa-rocket"></i></div>
                    <span>Lightning Processing</span>
                  </li>
                  <li className="mb-3 d-flex align-items-center">
                    <div className="icon-wrapper"><i className="fas fa-shield-alt"></i></div>
                    <span>Secured and Credible Document Capture</span>
                  </li>
                </ul>

                <div>
                  <p className="text-success text-center mb-3"><i className="fas fa-check-circle"></i> Highly Recommended</p>
                  <Button color="primary" className="w-100 mt-auto" onClick={() => { setModal(false); setIframeModal(true); }}>
                    Download Online
                  </Button>
                </div>
             
              </div>
              <div className="col-sm-6 text-left upload-option-offline d-flex flex-column">
                <h6 className="upload-title text-secondary">Offline</h6>
                <ul className="list-unstyled mb-3 flex-grow-1">
                  <li className="mb-3 d-flex align-items-center">
                    <div className="icon-wrapper"><i className="fas fa-hourglass-half"></i></div>
                    <span>Delayed Processing</span>
                  </li>
                  <li className="mb-3 d-flex align-items-center">
                    <div className="icon-wrapper"><i className="fas fa-user-check"></i></div>
                    <span>Manual Verification May Take a Couple of Days</span>
                  </li>
                  <li className="mb-3 d-flex align-items-center">
                    <div className="icon-wrapper"><i className="fas fa-search"></i></div>
                    <span>Document is Subjected to Various Scrutiny</span>
                  </li>
                  <li className="mb-3 d-flex align-items-center">
                    <div className="icon-wrapper"><i className="fas fa-redo"></i></div>
                    <span>May Be Asked to Resubmit Document if Not Found Credible</span>
                  </li>
                </ul>
                <Button color="secondary" className="w-100 mt-auto" onClick={() => { setModal(false); document.getElementById('dropzone-input').click(); }}>
                  Upload Offline
                </Button>
              </div>
            </div>
          </Modal>
          
          <Modal isOpen={iframeModal} toggle={() => setIframeModal(false)} size="xl" >
            <div className="modal-header">
              <h5 className="modal-title">Online Upload</h5>
              <button type="button" className="close" onClick={() => setIframeModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <iframe
                src="https://capture.yug.network/widget_url?id=Zjc4ODY2ODg2NTk3MzQwNTQzMzk3YTQyYzRjZjkwNDNlOGQ3MDBlNzIwMGNiOGEzYmVmY2Q0ZWEyZTBhMjFkNjg2YzRjY2Q0ZTczODY5YTgwNjI0NjNmMThhNjY0YWIzMGQzOWUyMWQ1OTY3NTBmMmJmNWI4YmI2Yzg0ZTFmNmMxZGUwYzI3OTVhY2ExODQ3ZjFmYmMzN2Q5YzUyZTg4YmUxM2NjZjA1NzE1ZDdlMmFkYWI3YzgxMDYxNmY4YmJhY2ZhZWNiZTNjMDFjZjliZjcxOTJjNjEwZjRlYzljNDZjYTUzYmYyNjM0MmUzMzdkMWI5ZmM2OGJlYTk0YTlmOA%3D%3D"
                style={{ width: '100%', height: '80vh' }}
                onLoad={() => {
                  console.log("DOCUMENT CAPTURING STARTED")
                  window.addEventListener('message', (event) => {
                    console.log({event : event.data})
                    if (event.data === '{"event":"DOCUMENT-CAPTURED"}') {
                      setIframeModal(false);
                    }
                  });
                }}
              />
            </div>
          </Modal>
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles);
            }}
            accept={supportedFiles.reduce((acc, fileType) => {
              if (fileType === "image") {
                acc["image/*"] = [".png", ".gif", ".jpeg", ".jpg"];
              } else if (fileType === "pdf") {
                acc['application/pdf'] = [];
              }
              return acc;
            }, {})}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone needsclick ms-2 mt-2" style={{ display: 'none' }}>
                <div className="dz-message" {...getRootProps()}>
                  <input id="dropzone-input" {...getInputProps()} />
                  {single && selectedFiles && selectedFiles.length == 1 && selectedFiles[0].path ?
                    <div className="d-flex align-items-center justify-content-between">
                      <FileUploadRowItem file={selectedFiles[0]} index={0} />
                      <div className=" d-flex align-items-end justify-content-end">
                        <h4>
                          <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t("Drag & Drop your files here or Choose Files.")}</div>
                          <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t(` ${fileSizeText ? fileSizeText : "100 MB"} max file size`)}</div>
                        </h4>
                      </div>
                    </div>
                    :
                    <h4>
                      <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t("Drag & Drop your files here or Choose Files.")}</div>
                      <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t(` ${fileSizeText ? fileSizeText : "100 MB"} max file size`)}</div>
                    </h4>
                  }
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}
    </Row>
  );
};

const FileUploadRowItem = ({ hideIndexes, file, index }) => {
  return (
    <div style={{ border: "1px solid #f0f0f0" }} className="d-flex pe-3 flex-1 w-100 align-items-center">
      {!hideIndexes && <Col className="col-auto">
        <div className="h3 fw-bold me-3">{index + 1}.</div>
      </Col>}
      <Col className="col-auto">
        {file?.type?.split("/")[0] === "application" && file.name.endsWith(".pdf") || file?.key?.endsWith('.pdf') ?
          <PDFLabel src={file?.key ?? file} />
          :
          typeof file === 'string' || !file.path ?
            <Image style={{ width: "60px", height: "60px" }} width="60px" height="60px" src={file?.key ?? file} />
            : <img
              src={file?.key}
              className=""
              style={{ objectFit: "contain", width: "60px", height: "60px" }} width="60px" height="60px" alt={file?.name}
            />}
      </Col>
      <Col className="ms-2 col-auto">
        <h3 className="text-muted font-weight-bold">
          {truncateFilename(file?.name ?? getFilenameFromPath(file?.key ?? file))}
        </h3>
        <p className="mb-0">
          <h4>
            {file.formattedSize}
          </h4>
        </p>
      </Col>
    </div>
  );
};

export default observer(DropZoneOnline);
