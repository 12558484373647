import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { Table } from "reactstrap";
import { SelectField } from "../../SelectField/SelectField";
import CountrySelectService from "./CountrySelectService";
import { I18nContext } from "../../../store/context/i18nContext";

const CountrySelect = ({
  isd,
  isoCode,
  noLabel,
  innerContainerClassName,
  innerContainerStyle,
  containerStyle,
  error,
  inputGroup,
  style,
  name,
  plain,
  placeholder,
  className,
  onChange,
  value,
  showErr,
  outline,
  inline,
  borderNone,
  RightComponent,
  onClickRight,
  label,
  required,
  showClear,
  noIP,
  dropDownMenuWidth,
  ...props
}) => {

  const [COUNTRIES, setCountries] = useState([]);
  const { t } = useContext(I18nContext);
  const [ipCountry, setIPCountry] = useState(null);


  useEffect(() => {
    const fetch = async () => {
      try {
        await CountrySelectService.fetch();
        setCountries(
          toJS(CountrySelectService.countries).map((c) => {
            return {
              ...c,
              value: isd ? c?.isd : isoCode ? c?.isoCode2 : c?.name,
              menuLabel: isd ? (
                <div className="d-flex align-items-center">
                  <div className="px-0 mx-0 d-flex justify-content-start" style={{
                    // minWidth: '30px'
                  }}>
                    {/* <img
                      src={c?.flag}
                      alt="flag"
                      style={{
                        display: "inline",
                        maxHeight: "15px",
                        marginRight: "10px"
                      }}
                    /> */}
                  </div>
                  <div style={{
                    minWidth: '30px',
                    fontSize: "14px",
                    marginRight: "6px"
                  }}>
                    {`+${c?.isd}`}
                  </div>
                  <div className="d-flex justify-content-start" style={{
                    minWidth: '60px',
                    fontSize: "14px"
                  }}>
                    {`${c?.name}`}
                  </div>
                </div>
              ) : name ? (
                <div>
                  {/* <img
                    src={c?.flag}
                    alt="flag"
                    style={{
                      display: "inline",
                      maxHeight: "15px",
                      margin: "5px",
                    }}
                  /> */}
                  {`${c.name}`}
                </div>
              ) : (
                <div>
                  {` ${c.name}`}
                </div>
              ),
              label: isd ? (
                <div className="d-flex align-items-center">
                  <div className="px-0 mx-0 d-flex justify-content-start" style={{
                    minWidth: '20px'
                  }}>
                    {/* <img
                      src={c?.flag}
                      alt="flag"
                      style={{
                        display: "inline",
                        maxHeight: "15px",
                        margin: "5px",
                        marginRight: "2px"
                      }}
                    /> */}
                  </div>
                  <div className="mx-1" style={{
                    minWidth: '30px',
                    fontSize: "14px"
                  }}>
                    {`+${c?.isd}`}
                  </div>
                  {/* <div className="d-flex justify-content-start" style={{
                    minWidth: '60px'
                  }}>
                    {`${c?.name}`}
                  </div> */}
                </div>
              ) : name ? (
                <div>
                  <img
                    src={c?.flag}
                    alt="flag"
                    style={{
                      display: "inline",
                      maxHeight: "15px",
                      margin: "5px",
                    }}
                  />
                  {`${c.name}`}
                </div>
              ) : (
                <div>
                  {` ${c.name}`}
                </div>
              ),
            };
          })
        );
      } catch (error) {
      }
    };
    fetch();
  }, []);

  const renderFunc = ({ data, innerRef, innerProps }) => {
    return (
      <div
        ref={innerRef}
        {...innerProps}
      >
        <Table style={{ margin: "0px" }} className="dropdown-hover">
          <thead style={{ margin: "0px" }}>
            <tr style={{ margin: "0px" }}>
              <td className="col-3 py-3">
                <img
                  src={data.flag}
                  alt="flag"
                  style={{
                    display: "inline",
                    maxHeight: "15px",
                    margin: "0px 5px",
                  }}
                />
              </td>
              <td className="col-3 py-3">{`+${data.isd}`}</td>
              <td className="col-6 py-3">{`${data.name}`}</td>
            </tr>
          </thead>
        </Table>
      </div>
    );
  };

  const formatOptionLabel = ({ label, menuLabel }, { context }) => {
    return context === 'menu' ? menuLabel : label;
  };


  const filterOption = useCallback((option, inputValue) =>
    !inputValue ||
    ["isd", "name"]?.some(
      key =>
        `${option.data[key]}`?.toLowerCase().includes(inputValue.toLowerCase())
    ),
    []);


  useEffect(() => {
    const fetchCountryCode = async () => {
      if (!value && isd) {
        try {
          const response = await fetch('https://ipapi.co/json');
          const data = await response.json();
          const countryCode = data.country_calling_code.replace('+', '');
          console.log("fetching country code:", countryCode);
          onChange({ value: countryCode });
          setIPCountry(countryCode)
        } catch (error) {
          console.error("Error fetching country code:", error);
          onChange({ value: 86 }); // Default to 86 if fetch fails
        }
      }
    };
    fetchCountryCode();
  }, [value]);


  return (
    <React.Fragment>
      {COUNTRIES.length > 0 && (

        <SelectField
          formatOptionLabel={formatOptionLabel}
          render={renderFunc}
          filterOption={filterOption}
          showClear={showClear}
          key={value}
          label={label ? label : t("Country")}
          error={
            error
          }
          required={required}
          containerStyle={containerStyle}
          data={COUNTRIES}
          inputGroup={inputGroup}
          innerContainerStyle={innerContainerStyle}
          innerContainerClassName={innerContainerClassName}
          placeholder={placeholder ?? ""}
          noLabel={noLabel}
          style={style}
          plain={plain}
          className={className}
          onChange={onChange}
          value={value ? value : ipCountry}
          outline={outline}
          showErr={showErr}
          inline={inline}
          borderNone={borderNone}
          RightComponent={RightComponent}
          onClickRight={onClickRight}

          {...props}
        />
      )}

      {!(COUNTRIES?.length > 0) && (
        <SelectField
          formatOptionLabel={formatOptionLabel}
          render={renderFunc}
          filterOption={filterOption}
          showClear={showClear}
          label={label ? label : t("Country")}
          isDisabled={true}
          error={
            error
          }
          containerStyle={containerStyle}
          data={COUNTRIES}
          inputGroup={inputGroup}
          dropDownMenuWidth={dropDownMenuWidth}
          innerContainerStyle={innerContainerStyle}
          innerContainerClassName={innerContainerClassName}
          className={className}
          placeholder={placeholder ?? ""}
          noLabel={noLabel}

          style={style}
          plain={plain}
          onChange={onChange}
          value={value}
          outline={outline}
          showErr={showErr}

          inline={inline}
          borderNone={borderNone}
          RightComponent={RightComponent}
          onClickRight={onClickRight}
        />
      )}
    </React.Fragment>
  );
};

export default observer(CountrySelect);
