import { BiSolidReport } from "react-icons/bi"
import { BsQuestionSquare, BsQuestionSquareFill } from "react-icons/bs"
import { CiCalculator2 } from "react-icons/ci"
import { FaUser, FaUserCog, FaWpforms } from "react-icons/fa"
import { FaBuildingShield, FaGear } from "react-icons/fa6"
import { GrDocumentConfig } from "react-icons/gr"
import { MdDashboard } from "react-icons/md"


export const menuItems = [


    {
        items: [
            {
            path: "/applications",
                icon: "uil-flask",
                name: "Applications",
                logo: MdDashboard,
            }
        ]
    },

    {
        items: [
            {
                path: "/#",
                name: "Configurations",
                logo: FaGear,
                subItems: [
                    {
                        path: "/role",
                        icon: "uil-flask",
                        name: "User Role",
                        logo: FaUserCog,
                    },
                    {
                        path: "/supervisor",
                        icon: "uil-flask",
                        name: "Users",
                        logo: FaUser,
                    },
                
                    {
                        path: "/questions",
                        name: "Questions",
                        logo: BsQuestionSquareFill,
                    },
                    {
                        path: "/reports",
                        name: "Reports",
                        logo: BiSolidReport,
                    },
                    {
                        path: "/risk-calculation",
                        name: "Risk Calculation",
                        logo: CiCalculator2,
                    },
                    {
                        path: "/doc-category",
                        name: "Document Categories",
                        logo: GrDocumentConfig,
                    },
                ]
            }
        ]
    },


  
]

