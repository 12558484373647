import React, { useEffect, useState } from "react";
import "./style.scss";

const ModalComponent = ({ openFrom, position, size, onToggle, isOpen, children, modalSizeStyle, modalCloseButton = true }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    useEffect(() => {
        if (openFrom) {
            if (isOpen) {
                setIsModalOpen(true);
            } else if (isModalOpen) {
                const timeout = setTimeout(() => {
                    setIsModalOpen(false);
                }, 2000);
                return () => clearTimeout(timeout);
            }
        } else {
            setIsModalOpen(isOpen);
        }
    }, [isOpen, openFrom])

    const handleClose = (e) => {
        onToggle(e);
    };

    const modalPositionClass = `___modal ___modal-${position}`;
    const modalOpensFromClass = `${openFrom === "right" ? !isOpen && isModalOpen ? " ___modal-closeFromRight " : " ___modal-opensFromRight " : ""}`;
    const modalSizeClass = `___modal-content ___modal-${size}`;

    return (
        <>
            {isModalOpen ? (
                <div >
                    <div className={`${modalPositionClass} ${modalOpensFromClass}`} >
                        <div className={`${modalSizeClass}`} style={modalSizeStyle ? { ...modalSizeStyle } : {}}
                        >
                            {modalCloseButton && <span className="___modal-close " onClick={handleClose}>
                                &times;
                            </span>}
                            {children}
                        </div>
                    </div >
                </div >
            ) : null}
        </>
    );
};

ModalComponent.Title = ({ children }) => (
    <div className="___modal-title">
        <h2>{children}</h2>
    </div>
);

ModalComponent.Body = ({ children, preventScroll = false }) => (
    <div className={`___modal-body ${preventScroll ? "___modal-body-prevent  " : " ___modal-body-scroll "}`}>
        {children}
    </div>
);

ModalComponent.Footer = ({ children }) => (
    <div className="___modal-footer">
        <div className="___modal-footer-content">{children}</div>
    </div>
);

export default ModalComponent;
