import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from "../../assets/images/Logo.svg";
import { DialogContext } from '../../store/context/DialogContext';
import { doGET } from '../../util/HttpUtil';

const ENDPOINTS = {
  detail: (key) => `/api/sys-config/${key}/detail`,
};

const formTypeToDisclosureVariable = {
  "individual-plus": "individualLiteDisclosure",
  "business-plus": "businessLiteDisclosure",
  "individual-prime": "businessPrimeDisclosure",
  "business-prime": "individualPrimeDisclosure",
}
const Disclosures = () => {
  const { formType } = useParams()
  const { showError } = useContext(DialogContext)
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);


  const fetchDisclosureContent = async () => {
    const key = formTypeToDisclosureVariable?.[formType]
    if (key) {
      setLoading(true)
      try {
        const response = await doGET(ENDPOINTS?.detail(key))
        if (response?.status === 200) {
          setContent(response.data?.[formTypeToDisclosureVariable[formType]] ?? "");
        }
      } catch (error) {
        showError(error)
      } finally {
        setLoading(false)
      }
    }

  }
  useEffect(() => {
    fetchDisclosureContent()
  }, [formType])

  return (
    <div style={{ height: "100vh", margin: "0px 30px" }} className={'d-flex justify-content-center align-items-center'}>
      <div className={'form-content '}>

        <div className='d-flex flex-column  justify-content-center  align-items-center  '>
          {<img
            src={Logo}
            alt="fv-bank"
            height="50"
            style={{ marginBottom: "10px" }}
            className="logo logo-dark"
          />}

          {
              <div className="" dangerouslySetInnerHTML={{ __html: content }}></div>
          }
        </div>
      </div>
    </div>
  )
}

export default Disclosures