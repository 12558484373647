import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  CardFooter,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { doGET, doPUT } from "../../util/HttpUtil";

const Pagination = ({ page, rowsPerPage, total, onChange, uiPreference }) => {
  const data = [
    {
      label: 20,
      value: "20",
    },
    {
      label: 50,
      value: "50",
    },
    {
      label: 100,
      value: "100",
    },
    {
      label: 200,
      value: "200",
    },
    {
      label: 400,
      value: "400",
    },
  ];

  const changePage = (page, rowsPerPage) => {
    if (
      page > 0 &&
      page <=
      (total % rowsPerPage === 0
        ? total / rowsPerPage
        : total / rowsPerPage + 1)
    ) {
      onChange(page, rowsPerPage);
    }
  };

  const [showMenu, setMenu] = useState(false);

  return (
    <CardFooter className="d-flex py-1 px-2 d-flex flex-1 justify-content-end" style={{ backgroundColor: '#FAFAFA' }}>
      <div className="d-flex flex-sm-row flex-column align align-items-center">
        <div className="d-flex mb-0  px-2">
          <Dropdown
            menuPlacement="top"
            onClick={(e) => e.preventDefault()}
            isOpen={showMenu}
            toggle={() => setMenu(!showMenu)}
          >
            <DropdownToggle
              onClick={(e) => e.preventDefault()}
              tag="button"
              className="btn btn-outline-secondary"
              style={{ padding: "0 5px 0 10px" }}
            // className="mr-sm-0 mr-n2"

            >
              <span style={{ float: "left", lineHeight: "30px" }}>
                {rowsPerPage} Rows {" "}
              </span>
              <span
                className="bx bx-chevron-up"
                style={{ fontSize: "24px", lineHeight: "28px" }}
              />
            </DropdownToggle>
            <DropdownMenu onClick={(e) => e.preventDefault()}>
              {data.map((d, key) => {
                const { label, value } = d;
                return (
                  <div key={key}>
                    <DropdownItem
                      onClick={async (e) => {
                        // e.preventDefault();
                        onChange(1, value);
                        await doPUT("/api/ui-preference/update", { key: `${uiPreference}.rows`, value: `${value}` });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {label} Rows
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                  </div>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        {/* <div className="d-flex align align-items-center"> */}
        <div style={{ fontSize: "12px" }} className="d-flex mt-0 mb-0  mx-2 d-none d-sm-block">
          Showing &nbsp;
          {total === 0 ? "0" : (page - 1) * rowsPerPage + 1} -{" "}
          {page * rowsPerPage > total
            ? total
            : page * rowsPerPage}{" "}
          {total > -1 ? " of " + total : ''}
        </div>
        <div className="d-flex my-0 mx-2 pt-1 pb-0">
          <div
            style={{ fontSize: "30px", height: "40px", marginTop: "-9px", color: (page == 1 ? "#AFAFAF" : "#666") }}
            role="button"
            className="page-item uil-angle-left"
            onClick={(e) => {
              e.preventDefault();
              changePage(page - 1, rowsPerPage);
            }}
          ></div>
          <div style={{ fontSize: "14px", minWidth: "40px", height: "30px" }} className="badge bg-secondary my-0 mx-2 pt-2">
            {page}
          </div>
          <div
            style={{ fontSize: "30px", height: "40px", marginTop: "-9px", color: (total <= (rowsPerPage * page) ? "#AFAFAF" : "#666") }}
            role="button"
            className="page-item  uil-angle-right"
            onClick={(e) => {
              e.preventDefault();
              changePage(page + 1, rowsPerPage);
            }}
          ></div>
        </div>
        {/* </div> */}
      </div>
    </CardFooter >
  );
};

export default observer(Pagination);
