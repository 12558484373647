import React from 'react';
import { Row } from 'reactstrap';
import { CountrySelect, InputField, SelectField } from '../../../../../components';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import { InputLabel } from './ProofOfDocument';
import "./style.css";
const ACCESS_TYPES = [
    { id: 0, type: "Viewer", text: "View payments and create new beneficiaries." },
    { id: 1, type: "Basic User", text: "All access of Viewer and initiate new payments. Payments initiated by basic users will require authorisation from owner (or) approver (or) a power user before the payment is submitted for further processing. Basic user cannot authorise payments." },
    { id: 2, type: "Approver", text: "All access of Basic User and approve payments initiated by others. Approver’s own initiated transactions will require authorisation from another Approver, Power User or Owner." },
    { id: 3, type: "Power User", text: "Same access as Owner" },
]

const User = ({ asLabel, data, handleChange, hasError, index, handleValidation, validationkeyValue }) => {
    return (
        <div>
            <InputLabel required label={"Name"} labelStyle={{ fontSize: 15 }} />
            <Row>
                <InputField
                    className="col-sm-6"
                    placeholder="First"
                    value={data?.users?.[index]?.firstName}
                    onChange={(v) => handleChange(`users[${index}].firstName`, v)}
                    error={handleValidation("userfirstName", data?.users?.[index]?.firstName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
       
                />
                <InputField
                    className="col-sm-6"
                    placeholder="Last"
                    value={data?.users?.[index]?.lastName}
                    onChange={(v) => handleChange(`users[${index}].lastName`, v)}
                    error={handleValidation(`userlastName`, data?.users?.[index]?.lastName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />
            </Row>

            <InputLabel required label={"Address"} labelStyle={{ fontSize: 15 }} />
            <Row>
                <InputField
                    placeholder="Building and Number"
                    value={data?.users?.[index]?.address?.buildingNumber}
                    onChange={(v) => handleChange(`users[${index}].address.buildingNumber`, v)}
                    error={handleValidation(`userBuildingNumber`, data?.users?.[index]?.address?.buildingNumber)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
            </Row>
            <Row>
                <InputField
                    placeholder="Street name"
                    value={data?.users?.[index]?.address?.street}
                    onChange={(v) => handleChange(`users[${index}].address.street`, v)}
                    error={handleValidation(`userStreet`, data?.users?.[index]?.address?.street)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
            </Row>
            <Row>
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="City"
                    value={data?.users?.[index]?.address?.city}
                    onChange={(v) => handleChange(`users[${index}].address.city`, v)}
                    error={handleValidation(`userCity`, data?.users?.[index]?.address?.city)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="State / Province / Region"
                    value={data?.users?.[index]?.address?.state}
                    onChange={(v) => handleChange(`users[${index}].address.state`, v)}
                    error={handleValidation(`userState`, data?.users?.[index]?.address?.state)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="Postal / Zip Code"
                    type="text"
                    value={data?.users?.[index]?.address?.pinCode}
                    onChange={(v) => handleChange(`users[${index}].address.pinCode`, v)}
                    error={handleValidation(`userPinCode`, data?.users?.[index]?.address?.pinCode)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />
                <CountrySelect
                 style={{ marginTop: '20px !important' }}
                    value={data?.users?.[index]?.address?.country}
                    onChange={(v) => handleChange(`users[${index}].address.country`, v?.value)}
                    error={handleValidation('userCountry', data?.users?.[index]?.address?.country)}
                    showErr={hasError}
                    noLabel
                    isoCode
                    placeholder={"Select Country"}
                    className="col-sm-6 col-md-3 with-margin-top"
                    isDisabled={asLabel}
                />
            </Row>

            <Row>
                {/* <PhoneNum
                    className='col-sm-6 mt-2'
                    label="Phone"
                    value={{
                        phone: data?.users?.[index]?.phone,
                        countryCode: data?.users?.[index]?.address?.countryCode
                    }}
                    onChange={(v) => {
                        handleChange(`users[${index}].phone`, v?.phone)
                        handleChange(`users[${index}].countryCode`, v?.countryCode)
                    }}
                    //error={handleValidation(`phone`, data?.users?.[index]?.phone)}
                    // showErr={hasError}
                    isDisabled={asLabel}
                /> */}
                 <InputField
                    className="col-sm-6"
                    label={"Phone"}
                    type="number"
                    value={data?.users?.[index]?.phone}
                    onChange={(v) => handleChange(`users[${index}].phone`, v)}
                    error={handleValidation("userphone", data?.users?.[index]?.phone)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required
                />
                <InputField
                    className="col-sm-6"
                    label={"Email"}
                    type="email"
                    value={data?.users?.[index]?.email}
                    onChange={(v) => handleChange(`users[${index}].email`, v)}
                    error={handleValidation(`useremail`, data?.users?.[index]?.email)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required
                />
            </Row>

            <Row>
                {/* <PhoneNum
                    className='col-sm-6 mt-2'
                    label="Mobile Phone"
                    value={{
                        phone: data?.users?.[index]?.mobilePhone,
                        countryCode: data?.users?.[index]?.address?.countryCode
                    }}
                    onChange={(v) => {
                        handleChange(`users[${index}].mobilePhone`, v?.phone)
                        handleChange(`users[${index}].countryCode`, v?.countryCode)
                    }}
                    //error={handleValidation(`mobilePhone`, data?.users?.[index]?.mobilePhone)}
                    // showErr={hasError}
                    isDisabled={asLabel}
                /> */}
                 <InputField
                    className="col-sm-6"
                    label={"Mobile Phone"}
                    type="number"
                    value={data?.users?.[index]?.mobilePhone}
                    onChange={(v) => handleChange(`users[${index}].mobilePhone`, v)}
                    error={handleValidation(`usermobilePhone`, data?.users?.[index]?.mobilePhone)}
                    showErr={hasError}
                    required
                    isDisabled={asLabel}
                />
                <SelectField
                    labelStyle={{ fontSize: 15 }}
                    className="col-sm-6"
                    label="Role"
                    data={validationkeyValue["role"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                    isDisabled={asLabel}
                    value={data?.users?.[index]?.role}
                    onChange={(v) => handleChange(`users[${index}].role`, v?.value)}
                    error={handleValidation(`role`, data?.users?.[index]?.role)}
                    showErr={hasError}
                    placeholder="Select"
                    required
                />
            </Row>

            <Row>
                <SelectField
                    labelStyle={{ fontSize: 15 }}
                    className=""
                    label="Account Access Type"
                    data={validationkeyValue["accessType"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                    isDisabled={asLabel}
                    placeholder="Select Account Accesss Type"
                    value={data?.users?.[index]?.accessType}
                    onChange={(v) => handleChange(`users[${index}].accessType`, v?.value)}
                    error={handleValidation(`accessType`, data?.users?.[index]?.accessType)}
                    showErr={hasError}
                    required
                />
            </Row>

            {
                ACCESS_TYPES.map(e => (<div className='my-2 fs-6' key={e.id}><span className="bold-text">{e.type}: </span>{e.text}</div>))
            }
            <QuestionRadioGroup
                field={`users[${index}].entityControl`}
                style={{ fontSize: 15 }}
                question="Is this person an Officer or a person with control over the entity?"
                data={data}
                handleChange={handleChange}
                isDisabled={asLabel}
                //defaultValue={false}
                error={handleValidation(`entityControl`, data?.users?.[index]?.entityControl)}
                hasError={hasError}
                required
                
            />

            <QuestionRadioGroup
                field={`users[${index}].isDirector`}
                style={{ fontSize: 15 }}
                question="Is this person a Director of the entity?"
                data={data}
                handleChange={handleChange}
                error={handleValidation('isDirector', data?.users?.[index].isDirector)}
                hasError={hasError}
                isDisabled={asLabel}
                //defaultValue={false}
                required
                
            />

            <QuestionRadioGroup
                field={`users[${index}].isAuthorized`}
                style={{ fontSize: 15 }}
                question="Is this person an authorized contact on the account?"
                data={data}
                handleChange={handleChange}
                error={handleValidation('isAuthorized', data?.users?.[index].isAuthorized)}
                hasError={hasError}
                isDisabled={asLabel}
                required
                //defaultValue={false}
            />

            <QuestionRadioGroup
                field={`users[${index}].isPrimaryUser`}
                style={{ fontSize: 15 }}
                question="Is this person the Primary User of the Account?"
                data={data}
                handleChange={handleChange}
                error={handleValidation('isPrimaryUser', data?.users?.[index].isPrimaryUser)}
                hasError={hasError}
                //error={hasError}
                isDisabled={asLabel}
                required
                //defaultValue={false}
                
            />

            <QuestionRadioGroup
                field={`users[${index}].isAgent`}
                style={{ fontSize: 15 }}
                question="Is this person an agent of the company operating under a power of attorney?"
                data={data}
                handleChange={handleChange}
                error={handleValidation('isAgent', data?.users?.[index].isAgent)}
                isDisabled={asLabel}
                hasError={hasError}
                required
            />
        </div>
    )
}

export default User;