import React, { useContext, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { DialogContext } from '../../../../../store/context/DialogContext'
import { doGET } from '../../../../../util/HttpUtil'
import ProofOfDocument from './ProofOfDocument'
import ButtonGroup from './ButtonGroup'

const Page5 = ({ loading , onNextClick = () => { }, onBackClick = () => { }, step , isAskForInfo, remark, recordId, asLabel, handleUpdate, setHasError, formId, data, handleValidation, validationkeyValue }) => {
    const [internalLoading, setInternalLoading] = useState(false)
    const { showError } = useContext(DialogContext)
    const [documents, setDocuments] = useState([]);
    const [errors, setErrors] = useState([]);

    // const [sourceOfFundsDocuments, setSourceOfFundsdocuments] = useState([]);
    const checkIsDocumentUploaded = async ({ recordId, ubo_id, type }) => {
        setInternalLoading(true);
        try {
            const response = await doGET(`/api/application-doc/grid?form_id=${recordId}${ubo_id ? `&ubo_id=${ubo_id}` : ""}&type=${type}`);
            if (response?.status === 200 && response?.data?.rows[0]?.attachment?.urls?.length) {
                return true;
            }
        } catch (error) {
            showError(error);
        } finally {
            setInternalLoading(false);
        }
        return false;
    };

    const handleSubmit = async () => {
        setErrors([]); 

        const newErrors = [];

        await Promise.all(documents.map(async (doc) => {
            let isDocumentUploaded = await checkIsDocumentUploaded({ ubo_id: doc?._id, type: "ProofOfAddress", recordId });
            if (!isDocumentUploaded) {
                newErrors.push({ error: `Upload a proof of address for ${doc?.firstName} ${doc?.lastName}`, type: "ProofOfAddress", ubo_id: doc?._id });
            }
            if (sourceOfFunds(doc)) {
                isDocumentUploaded = await checkIsDocumentUploaded({ ubo_id: doc?._id, type: "SourceOfFunds", recordId });
                if (!isDocumentUploaded) {
                    newErrors.push({ error: `Upload proof of source of funds for ${doc?.firstName} ${doc?.lastName}`, type: "SourceOfFunds", ubo_id: doc?._id });
                }
            }
        }));

        if (newErrors.length > 0) {
            setHasError(true);
            setErrors(newErrors);
            showError(newErrors[0]?.error);
            return;
        }

        setHasError(false);
        onNextClick();
    };


    const IdentifyDocsForUbo = () => {
        try {
            setDocuments(() => []);

            // const authorizedUsers = data?.users.filter(user => user.isAuthorized) || [];
            const shareholders = data?.shareholders.filter(shareholder => parseFloat(shareholder.percentOwner) >= 10) || [];
            // const shareholdersWithHighOwnership = data?.shareholders.filter(shareholder => parseFloat(shareholder.percentOwner) >= 25) || [];

            setDocuments(() => [
                ...data?.users,
                ...shareholders
            ]);

            // setSourceOfFundsdocuments(() => [
            //     ...shareholdersWithHighOwnership
            // ]);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        IdentifyDocsForUbo();
    }, [])

    const sourceOfFunds = (user) => {
        return (user?.percentOwner >= 25);
    }

    return (
        <div>
            <h2 className='pt-3'>Document Upload Center</h2>
            <p style={{ fontSize: 12, fontStyle: 'italic' }}>Please upload all of the required documents for your application. If you do not have them ready, you can save this form and return later to complete the application.</p>

            <div className='py-2'>Important things to note:</div>
            <ul>
                <li>All documents must be in English or accompanied by an official certified translated copy.</li>
                <li>Register of directors and shareholders must be certified by CPA/Lawyer/Notary.</li>
                <li>Please read the help text below the upload box for each document and its requirements.</li>
            </ul>

            {/* <ProofOfDocument
                isAskForInfo={isAskForInfo}
                remark={remark}
                recordId={recordId}
                asLabel={asLabel}
                label={"Upload proof of Business Tax ID (If USA incorporated , please upload proof of EIN.)"}
                field={"businessTaxIdProof"}
                showCategory={"BusinessTaxIdProof"}
                type={"BusinessTaxIdProof"}
            /> */}

            {
                documents?.map((ele, idx) =>
                    <Card className='p-3'>
                        <ProofOfDocument
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            handleUpdate={handleUpdate}
                            label={`Upload a proof of address for the ${ele?.firstName} ${ele?.lastName}`}
                            field={"addressProof"}
                            showCategory={"ProofOfAddress"}
                            ubo_id={ele?._id}
                            type={"ProofOfAddress"}
                            handleValidation={handleValidation}
                            formId={formId}
                            single
                            supportedFiles={["pdf"]}
                            error={errors.find(err => err.ubo_id === ele?._id && err.type === "ProofOfAddress")?.error}
                        />
                        {
                            sourceOfFunds(ele) ? <ProofOfDocument
                                isAskForInfo={isAskForInfo}
                                remark={remark}
                                recordId={recordId}
                                asLabel={asLabel}
                                handleUpdate={handleUpdate}
                                label={`Upload proof of source of funds/​wealth of the ${ele?.firstName} ${ele?.lastName}`}
                                field={"sourceOfSHFundsProof"}
                                showCategory={"SourceOfFunds"}
                                ubo_id={ele?._id}
                                type={"SourceOfFunds"}
                                handleValidation={handleValidation}
                                formId={formId}
                                error={errors.find(err => err.ubo_id === ele?._id && err.type === "SourceOfFunds")?.error}
                            /> : ""
                        }
                    </Card>

                )
            }
            {/* 
            <ProofOfDocument
                isAskForInfo={isAskForInfo}
                remark={remark}
                recordId={recordId}
                asLabel={asLabel}
                handleUpdate={handleUpdate}
                label={"Upload a proof of address for the principal place of business (operating address). (Rental/​lease agreement, Utility bill or bank statement issued within 90 days)"}
                field={"principleAddressProof"}
                showCategory={"Business_Proof_Address"}
                type={"Business_Proof_Address"}
            />
            <ProofOfDocument
                isAskForInfo={isAskForInfo}
                remark={remark}
                recordId={recordId}
                asLabel={asLabel}
                handleUpdate={handleUpdate}
                label={"Upload a source of funds for the business (i.e. bank statement, trading account statement, invoice or contract.)"}
                field={"sourceOfBusFundsProof"}
                showCategory={"SourceOfBusFundsProof"}
                type={"SourceOfBusFundsProof"}
            /> */}

            <p className='fs-6 py-2'>
                Once you submit your application, each of the Officers, Authorized Users and Shareholders with 25% or more equity will be sent a text message to verify their idenitiy. Please be sure each person completes the indetity verification within 48 hours or the applicaiton will be placed on hold.
            </p>

            <ButtonGroup loading={internalLoading || loading} asLabel={asLabel} onNextClick={handleSubmit} step={step} onBackClick={onBackClick} />


        </div>
    )
}

export default Page5;