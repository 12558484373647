import React from 'react';
import { Button } from 'reactstrap'; // Make sure to import Button from your UI library
import { ThreeBounce } from 'better-react-spinkit';
import { FaCheck } from 'react-icons/fa';

export default function PrimaryButton({ isDisabled, className,  onClick, loading , children}) {

  const nextButtonStyle = {
    backgroundColor: '#1678AE',
    height: "40px",
    padding: "2px 4px 2px 4px",
    fontSize: "16px",
    minWidth:"100px" ,
    padding : "0px 20px",
    borderRadius: "10px"
  }


  if (loading) {
    return (
      <Button
        style={nextButtonStyle}
        variant="primary"
        disabled
        className={className}
      >
        <ThreeBounce size={10} color="#FFFFFF" />
      </Button>

    );
  }
  return (
    <Button
      style={nextButtonStyle}
      variant="primary"
      disabled={isDisabled}
      onClick={onClick}
      className={className ?? "px-3"}

    >
      {children}
    </Button>
  );
}
