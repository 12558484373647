import React from "react";

// Dashboard
import Dashboard from "../pages/Dashboard/DashboardScreen";

//

//Pages
// import PagesStarter from "../pages/Utility/pages-starter"
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesMaintenance from "../pages/Utility/pages-maintenance";

// Authentication related pages
import Loginnew from "../pages/Authentication/Auth";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import Login1 from "../pages/AuthenticationInner/Login";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Register1 from "../pages/AuthenticationInner/Register";


import LoginIn from "../pages/Login/LoginIn";
// import BusinessLite from "../pages/Form/forms/ApplyBusinessLite";
import Disclosures from "../pages/Disclosures/Disclosures";
import ApplyIndividualLite from "../pages/Form/forms/ApplyIndividualLite";
import ApplyIndividualLiteContent from "../pages/Form/forms/ApplyIndividualLiteContent";
import ApplyBusinessLite from "../pages/Form/forms/BusinessLiteForms/ApplyBusinessLite";
import ApplyBusinessLiteContent from "../pages/Form/forms/BusinessLiteForms/ApplyBusinessLiteContent";
import ApplyBusinessPrime from "../pages/Form/forms/BusinessPrime/ApplyBusinessPrime";
import ApplyBusinessPrimeContent from "../pages/Form/forms/BusinessPrime/ApplyBusinessPrimeContent";
import PostKYC from "../pages/Form/forms/PostKYC";
import SelectScreen from "../pages/Form/forms/SelectScreen";
import UboDashboard from "../pages/Form/UboDashboard";
import DocumentSuccess from "../pages/static/Ondato/DocumentSuccess";
import OndatoSuccess from "../pages/static/Ondato/OndatoSuccess";
import ComingSoon from "../pages/Utility/ComingSoon";


const userRoutes = [
  { path: "/business-plus/:edit_id", Component: ApplyBusinessLite, forUser: true },
  { path: "/business-prime/:edit_id", Component: ApplyBusinessPrime, forUser: true },
  { path: "/verify-individual/dashboard", Component: UboDashboard, forUser: true },
  { path: "/individual-plus/:individual_id", Component: ApplyIndividualLite, forUser: true },
];

const openRoutes = [
  // { path : "/" , Component : Login},
  { path: "/logout", Component: Logout },
  { path: "/post-kyc", Component: PostKYC, forUser: true },
  {
    path: "/",
    exact: true,
    Component: () => {
      return <SelectScreen />
    },
  },
  { path: "/individual-plus/login", Component: () => { return <Loginnew isUser form={{ apiPath: "individual-lite",  path: "individual-plus", formType: "INDIVIDUAL_PLUS", unauthUrl: "individual-plus",name: "Individual Plus" }} /> } },
  { path: "/business-plus/login", Component: () => { return <Loginnew isUser form={{ apiPath: "business-lite", path: "business-plus", formType: "BUSINESS_PLUS", unauthUrl: "business-plus", name: "Business Plus" }} /> } },
  { path: "/business-prime/login", Component: () => { return <Loginnew isUser form={{ apiPath: "business-lite", path: "business-prime", formType: "BUSINESS_PRIME", unauthUrl: "business-prime", name: "Business Prime" }} /> } },
  { path: "/verify-individual", Component: () => { return <Loginnew isUser form={{ redirectPath: "/verify-individual/dashboard", type : "kyc", name: "Login", unauthUrl: "individual-plus", }} /> } },
  {
    path: "/individual-plus", Component: () => { return <ApplyIndividualLiteContent /> }
  },
  // { path: "/business-plus", Component: () => { return <Loginnew isUser form={{ path: "business-plus", name: "Business Plus" }} /> } },
  { path: "/business-plus", Component: () => { return <ApplyBusinessLiteContent /> } },
  { path: "/business-prime", Component: () => { return <ApplyBusinessPrimeContent /> } },
  { path: "/business-prime", Component: () => { return <ApplyBusinessPrimeContent /> } },
  { path: "/kyc/success", Component: () => { return <OndatoSuccess /> } },
  { path: "/document-captured/success", Component: () => { return <DocumentSuccess /> } },

  { path: "/forgot-password", Component: ForgetPwd },
  { path: "/:formType/disclosure", Component: Disclosures },
  { path: "/register", Component: Register },
  { path: "/login", Component: LoginIn },
 

  { path: "/pages-maintenance", Component: PagesMaintenance },
  { path: "/pages-comingsoon", Component: ComingSoon },
  { path: "/pages-404", Component: Pages404 },
  { path: "/pages-500", Component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", Component: Login1 },
  { path: "/pages-register", Component: Register1 },
  { path: "/page-recoverpw", Component: Recoverpw },
  { path: "/auth-lock-screen", Component: LockScreen },
];

export { openRoutes, userRoutes };
