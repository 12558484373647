import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';
import { DialogContext } from '../../store/context/DialogContext';
import { GrImage } from 'react-icons/gr';

const Image = ({ src, placeholder = "Loading...", style, ...restProps }) => {
    const [imageSrc, setImageSrc] = useState();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false); // State to control modal visibility
    const { showError } = useContext(DialogContext);

    const fetchImage = async (src) => {
        setLoading(true);
        if (src) {
            try {
                const response = await axios({
                    url: src,
                    headers: {
                        Authorization: localStorage.getItem("BearerToken"),
                    },
                    withCredentials: true,
                    responseType: "blob",
                });
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                };
                reader.readAsDataURL(response?.data);
            } catch (error) {
                console.error("Error fetching image:", error);
                showError("Error fetching image");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (src && modal && (src.slice(1, 4) === "api")) {
            fetchImage(src);
        }
    }, [src, modal]);

    const toggleModal = () => setModal(!modal);

    if (loading) {
        return <div className="image-skeleton mt-0 pt-0" style={{ backgroundColor: "#eee", ...style }}></div>;
    }

    return (
        <div>
            {src  ?
                <>
                    {/* <img
                        key={src}
                        src={imageSrc}
                        alt="Loaded Image"
                        style={{ cursor: 'pointer', ...style }}
                        onClick={toggleModal}
                        {...restProps}
                    /> */}

                    <div className='d-flex justify-content-center align-items-center' style={{ width: "60px", height: "60px", cursor: "pointer" }}>
                        <GrImage onClick={() => { if (src) toggleModal() }} size={40} />
                    </div>


                    <Modal isOpen={modal} toggle={toggleModal} centered>
                        <ModalBody>
                            <img src={imageSrc} alt="Modal Image" style={{ width: '100%' }} />
                        </ModalBody>
                    </Modal>
                </>
                : <i className='bx bx-image-alt font-size-20' />}
        </div>
    );
};

export default Image;
