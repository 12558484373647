import _ from "lodash";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Select, { components } from "react-select";
import { I18nContext } from "../../store/context/i18nContext";
import "./style.css";


const Menu = (props) => {
  return (
    <Fragment>
      <components.Menu {...props} className="w-100">
        <div className="w-100">
          {props?.selectProps.fetchingData ? (
            <span className="fetching">Fetching data...</span>
          ) : (
            <div>{props?.children}</div>
          )}

          <div
            className={"change-data d-flex justify-content-center w-100 "}
            onClick={props?.selectProps.changeOptionsData}
          >
            <props.selectProps.bottomBarChildren />
          </div>
        </div>
      </components.Menu>
    </Fragment>
  );
};


export const GroupedSelectField = (props) => {
  const {
    value,
    wrapperStyle,
    data,
    asLabel,
    inline,
    className,
    grouped,
    containerStyle,
    suggestion,
    innerContainerStyle,
    isDisabled,
    onClickLeft,
    LeftComponent,
    label,
    noLabel,
    showBottomBar,
    bottomBarChildren,
    changeOptionsData,
    renderFunc,
    showClear,
    onMenuScrollToBottom,
    plain,
    onInputChange,
    required,
    RightComponent,
    onClickRight,
    showErr,
    error,
    flexible,
    AddEntityComponent,
    onChange = () => { },
    style,
    innerContainerClassName,
    containerClassName,
    onBlur,
    inputValue,
    inputGroup,
    outline,
    filterOption
  } = props;

  const { t } = useContext(I18nContext);
  const [showAddComponent, setShowAddComponent] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [internalValue, setInternalValue] = useState(null);
  const [inputLabel, setInputLabel] = useState();
  const selectRef = useRef(null);


  useEffect(() => {

    if (data?.length) {
      data?.forEach((entry) => {
        entry?.options?.forEach((field) => {
          if ((field?.value == value || field?.value == value?.value) && internalValue?.value != value ) {
            setInternalValue(field);
            setInputLabel(field)
          }
        })
      })
    } else {
      setInternalValue(null)
    }
  }, [value, data]);


  if (asLabel) {
    return <div>{internalValue.label}</div>;
  }

  const hideValuesOptionn = (option, rawInput) => {
    if (filterOption?.some((selectedOption) => selectedOption === option.value)) {
      return false;
    }

    return option.label?.toLowerCase().includes(rawInput?.toLowerCase());
  };


  return (
    <React.Fragment>
      <div
        style={{
          ...wrapperStyle,
          ...style,
        }}
        className={`${inline ? "" : "selectfield__wrapper "} ${className}`}
      >
        <div
          style={containerStyle}
          className={
            ` ${!(plain || outline || inline) ? "d-flex justify-content-center align-items-center " : " "}      selectfield__container ` +
            (plain && !onFocus ? "selectfield__plain " : "") +
            containerClassName
          }
        >



          {suggestion && (
            <b className="selectfield__suggestion">{suggestion}</b>
          )}
          <div
            id="x1"
            className={
              "selectfield__innercontainer d-flex  flex-1 " + innerContainerClassName
            }
            style={{
              ...innerContainerStyle,
              backgroundColor: isDisabled ? "hsl(0, 0%, 95%)" : "",
            }}
          >
            {LeftComponent && (
              <div
                id="x2"
                onClick={(e) =>
                  onClickLeft ? onClickLeft(e) : null
                }
                className={
                  onClickLeft
                    ? "component_pointer Selectfield__leftComponent"
                    : "selectfield__leftComponent"
                }
                style={{ height: inline ? "19px" : "28px" }}
              >
                < LeftComponent />
              </div>
            )}
            <div
              className={internalValue?.groupLabel ? " input-group w-100  x1 " : "selectfield x1"}
              id="x5"
            >

              {internalValue?.groupLabel && (
                <div className="group-label-container">
                  <div
                    style={{ border: "none", fontSize: 11, maxWidth: "150px" }}
                    className="input-group-text grouped-input-group group-label-truncate"
                  >
                    {t(internalValue?.groupLabel)}
                  </div>
                  <span className="group-label-tooltip">{t(internalValue?.groupLabel)}</span>
                </div>
              )}


              
              <Select
                filterOption={hideValuesOptionn}
                components={
                  showBottomBar
                    ? internalValue
                      ? {
                        IndicatorSeparator: () => null,
                        components,
                        Menu,
                        DropdownIndicator: () => null,
                      }
                      : { IndicatorSeparator: () => null, Menu }
                    : internalValue
                      ? {
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }
                      : { IndicatorSeparator: () => null }
                }
                bottomBarChildren={bottomBarChildren}
                changeOptionsData={changeOptionsData}
                optionComponent={renderFunc}  
                onMenuScrollToBottom={onMenuScrollToBottom}
                menuPlacement="auto"
                menuPosition="fixed"
                maxMenuHeight={250}
                onInputChange={onInputChange}
                setValue={inputValue}
                value={inputLabel}
                styles={inline ? stylesForInline : inputGroup ?
                  stylesForInputGroup : stylesForMain}
                ref={selectRef}
                isClearable={showClear && !plain && !inline}
                onFocus={() => {
                  if (props?.onFocus) props?.onFocus();
                  setOnFocus(true);
                }}
                onBlur={() => {
                  if (onBlur) onBlur();
                  setOnFocus(false);
                }}
                options={data}
                onChange={(v) => {
                  onChange(v)
                  setInternalValue(v)
                  setInputLabel(v)
                }}
                className={
                  "form-control px-0 py-0 border-0 bg-transparent"
                }
                placeholder={
                  noLabel || onFocus || plain || inline || !(inline || outline || plain)
                    ? t(label)
                    : ""
                }
                isDisabled={isDisabled || false}
              />
              {!plain && !value?.length && !onFocus && !noLabel && !inline && (inline || outline || plain) && (
                <label
                  onClick={() => (!onFocus ? selectRef.current.focus() : null)}
                  style={
                    isDisabled
                      ? { backgroundColor: "hsl(0, 0%, 95%)" }
                      : {}
                  }
                  className={

                    "selectfield__label px-2 "

                  }
                >
                  {t(label)}
                  {required && <font color="red">*</font>}
                </label>
              )}
            </div>

            {RightComponent && !inline && (
              <div
                id="x6"
                onClick={(e) =>
                  onClickRight ? onClickRight(e) : null
                }
                style={{ position: "relative", height: inline ? "19px" : "28px" }}
                className={
                  onClickRight
                    ? "component_pointer Selectfield__rightComponent"
                    : "selectfield__rightComponent"
                }
              >
                < RightComponent />
              </div>
            )}
          </div>
          {error && showErr && (
            <em className="selectfield__error">{error}</em>
          )}
        </div>
        {flexible && (
          <button onClick={() => setShowAddComponent(true)}>Add Entity</button>
        )}
      </div>
      {
        showAddComponent && flexible && AddEntityComponent && (
          <section
            style={{
              height: "100vh",
              width: "100vw",
              backgroundColor: "white",
              position: "fixed",
              top: "0",
              left: "0",
              zIndex: "1500",
            }}
          >
            < AddEntityComponent setShowAddComponent={setShowAddComponent} />
          </section>
        )
      }
    </React.Fragment >
  );
};

const stylesForMain = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    height: 24,
    maxHeight: 24,
    minHeight: 24,
    backgroundColor: "transparent !important",
  }),
  groupHeading: (base) => ({
    ...base,
    color: '#808080',
    backgroundColor: "#EBEBEB",
    padding: "10px",
    margin: "0px",
  }),
  singleValue: (base) => ({ ...base, padding: "10px 0 0 0", backgroundColor: "transparent !important" }),
  indicatorsContainer: (base) => ({ ...base, height: 22 }),
  valueContainer: (base) => ({ ...base, padding: "0px 0 0 0", paddingLeft: "calc(1rem - 3px)", alignItems: "start", backgroundColor: "transparent !important" }),
  placeholder: (base) => ({ ...base, padding: "0px 0 0 0", backgroundColor: "transparent !important" }),
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
  }),
};

const stylesForInputGroup = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    height: 24,
    maxHeight: 24,
    minHeight: 24,
    fontSize: 12
  }),
  groupHeading: (base) => ({
    ...base,
    color: 'white',
    backgroundColor: "#707070",
    fontSize: 12
  }),
  singleValue: (base) => ({ ...base, fontSize: 12 }),
  indicatorsContainer: (base) => ({ ...base, height: 22 }),
  valueContainer: (base) => ({ ...base, padding: 0, paddingLeft: "calc(1rem - 3px)", lineHeight: "14px", fontSize: 12 }),
  placeholder: (base) => ({ ...base, paddingLeft: 0 }),
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
  }),
};

const stylesForInline = {
  control: (base) => ({
    ...base,
    border: "none",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: "none",
    minHeight: "18px",
    height: "18px",
    fontSize: 12
  }),
  container: (base) => ({
    ...base,
    border: "none",
  }),
  placeholder: (base) => ({ ...base, fontSize: 11 }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "18px",
    minWidth: "40px",
    padding: "0 6px",
  }),
  groupHeading: (base) => ({
    ...base,
    color: 'white',
    backgroundColor: "#707070"
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    // height: "10px",
    padding: 0,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "18px",
  }),
};
