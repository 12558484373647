import React from 'react';
import { Button } from 'reactstrap'; // Make sure to import Button from your UI library

export default function GoBackButton({ onClick, loading , }) {

  const nextButtonStyle = {
    // backgroundColor: '#1678AE',
    height: "40px",
    padding: "2px 4px 2px 4px",
    fontSize: "16px",
    width: "90px",
    borderRadius: "10px"
  }



  if (loading) {
    return (
      <Button
        style={nextButtonStyle}
        variant="outline-primary"
        disabled
      >
        Back
      </Button>

    );
  }
  return (
    <Button
      style={nextButtonStyle}
      variant="primary"
      onClick={onClick}
    >
      Back
    </Button>
  );
}
