import _isEqual from "lodash/isEqual";
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { default as ReactTable, default as useTable } from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table/react-table.css";
import {
  CardFooter,
  Col, Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { CrossButton, Pagination, SaveButton } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doGET, doPUT } from "../../util/HttpUtil";
import UIPrefService from "./UIPrefService";
import "./styles.css";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const DataGrid = ({
  data,
  renderLastCol,
  headers,
  onSelectChange,
  selectedIDs = [],
  total,
  page,
  rowsPerPage,
  hideCheckboxes = false,
  onPaginationChange,
  uiPreference
}) => {

  const { t } = useContext(I18nContext);
  const [showGridConfigModal, setShowGridConfigModal] = useState(false);
  const { showMessage } = useContext(DialogContext);

  const prepareUIPref = (allSelected) => {
    const actionColumn = {
      title: <input
        type="checkbox"
        className="me-2"
        style={{ marginLeft: "4px" }}
        checked={allSelected}
        onChange={() => {
          let allIds = data.map((d) => {
            return d._id;
          });
          onSelectChange(allSelected ? [] : allIds);
          setUiPrefData(prepareUIPref(!allSelected));
        }}
      />,
      accessor: "check",
      fixed: "left",
      width: 50,
      show: true,
      required: true
    };

    if (headers && headers?.columns)
      return { ...headers, columns: [!hideCheckboxes && actionColumn, ...headers?.columns] }
    else {
      return { ...headers, columns: [!hideCheckboxes && actionColumn,] };
    }
  }


  const checkAllSelected = (data, selectedIDs) => {
    for (let i = 0; i < data.length; i++) {
      if (!selectedIDs.includes(data[i]._id))
        return false;
    }
    return true;
  }


  const [uiPrefData, setUiPrefData] = useState(prepareUIPref(checkAllSelected(data, selectedIDs)));

  const [scrollPosition, setScrollPosition] = useState(300);
  let tableRef = useRef(null);
  const [gridHeight, setGridHeight] = useState();



  const reSizeTable = () => {
    const divElements = document.getElementsByClassName('ReactTable');
    if (divElements.length > 0) {
      const rect = divElements[0].getBoundingClientRect();
      const newHeight = rect.height + window.innerHeight - rect.bottom - 75;
      const tableElements = document.querySelectorAll('.ReactTable .rt-table');
      const tableMaxHeight = tableElements[0]?.scrollHeight + 6;
      const tableHeight = newHeight > tableMaxHeight ? tableMaxHeight : newHeight;
      if (tableHeight < data?.length * 42) {
        setGridHeight(tableHeight)
      } else {
        setGridHeight(data?.length * 42 + 50);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', reSizeTable);
    reSizeTable();
    return () => {
      window.removeEventListener('resize', reSizeTable);
    };
  }, []);

  useEffect(() => {
    reSizeTable();
  }, [data])

  const populatePreferenceData = useCallback(async () => {
    try {
      const response = UIPrefService?.uiPrefs[uiPreference] ? { status: 200, data: { value: UIPrefService?.uiPrefs[uiPreference] } } : await doGET("/api/ui-preference/" + "detail?id=" + uiPreference );
      UIPrefService?.updatePrefs(uiPreference, response?.data?.value ?? "[]")
      if (response?.status == 200 && response?.data?.value) {
        const hiddenColumns = JSON?.parse(response?.data?.value)

        for (const column of uiPrefData.columns) {
          if (hiddenColumns.includes(column.title)) {
            column.show = false;
          } else {
            column.show = true
          }
        }
      }
    } catch (e) { }
  }, [uiPreference])

  useEffect(() => {
    if (uiPreference) populatePreferenceData();
  }, [])


  const renderGridCell = (dataObj, field, renderer) => {
    if (field === "check") {
      return (
        <input
          type="checkbox"
          className="ms-2"
          checked={selectedIDs.includes(dataObj._id)}
          onChange={() => {
            const newSelectedIDs = selectedIDs.includes(dataObj._id)
              ? selectedIDs?.filter((item) => item !== dataObj._id)
              : [...selectedIDs, dataObj._id];
            onSelectChange(newSelectedIDs);
            setUiPrefData(prepareUIPref(newSelectedIDs?.length == data?.length));
          }}
        />
      );
    }
    if (field === "action") {
      return renderLastCol(dataObj);
    } else {
      return renderer ? renderer(dataObj) : dataObj[field];
    }
  };

  const getColumns = useCallback(() => {
    return uiPrefData?.columns?.map((column) => {
      return {
        Header: t(column.title),
        accessor: column?.accessor,
        fixed: column.fixed,
        width: column.width,
        show: column.show,
        Cell: (row) => {
          return (
            <div className=" height-100 w-100 d-flex align-items-center" >{renderGridCell(row.original, column.accessor, column.renderer)}</div>
          );
        },
      };
    });
  }, [uiPrefData]);

  const handleToggleVisibleColumn = (column) => {
    let updatedColumns = uiPrefData?.columns?.map((col) => {
      if (col.accessor === column.accessor) {
        return { ...col, show: !col.show };
      }
      return col;
    });
    setUiPrefData({ ...uiPrefData, columns: updatedColumns });
  };

  const saveGridConfig = async () => {
    try {
      let hiddenColumns = uiPrefData?.columns
        .filter(col => !col.show) // Filter hidden columns
        .map(col => col.title);   // Map hidden column titles
      await doPUT("/api/ui-preference/update", { key: uiPreference, value: JSON?.stringify(hiddenColumns) });
      UIPrefService?.updatePrefs(uiPreference, JSON?.stringify(hiddenColumns))

      showMessage("Preference updated successfully", "Success");
      setShowGridConfigModal(false);
    } catch (e) {
      console.error(e);
    }
  }

  const { getTableProps } = useTable;


  const getTable = useCallback((...args) => {
    return {
      ...(getTableProps && getTableProps(...args)),
      style: { height: 801, overflow: "auto", scrollTop: scrollPosition },
    };
  }, [scrollPosition, getTableProps]);

  const reactTableProps = useMemo(() => {
    return {
      data: data,
      columns: getColumns(),
      defaultPageSize: data?.length > rowsPerPage ? rowsPerPage : data?.length,
      className: "-striped -highlight",
      showPagination: false,
      getTableProps: getTable,
      sortable: false,
    };
  }, [data, rowsPerPage, getColumns, getTable])

  if (reactTableProps?.defaultPageSize == 0) {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <div className="text-center">
              <div>
              </div>
              <h1 className="display-7  mt-4 mb-3">No records available.</h1>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <div className="flex-1">
      <ReactTableFixedColumns key={reactTableProps?.defaultPageSize} {...reactTableProps} style={{ height: gridHeight }} />

      <CardFooter className=" p-0 d-flex align-items-center justify-content-end" style={{ backgroundColor: '#FAFAFA' }}>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          total={total}
          uiPreference={uiPreference}
          onChange={onPaginationChange}
        />
        {uiPreference && <i onClick={() => setShowGridConfigModal(!showGridConfigModal)} className="dripicons-gear h2 mt-2 m-2 px-3" />}

        <Modal scrollable centered={true} isOpen={showGridConfigModal} >
          <ModalHeader><h4>{t('Show Columns')}</h4></ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column" >
              {uiPrefData?.columns?.map((column) => (
                <label className={column.required ? "d-none" : "d-flex align-items-center"} key={column.accessor} >
                  <input
                    type="checkbox"
                    checked={column.show}
                    onChange={() => handleToggleVisibleColumn(column)}
                  />
                  <div className="mx-3  font-weight-bold" >{column.title}</div>
                </label>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <SaveButton style={{ height: "36px" }} onClick={saveGridConfig} />
            <CrossButton onClick={() => setShowGridConfigModal(false)} />
          </ModalFooter>
        </Modal>
      </CardFooter>
    </div>
  );
};

export default memo(DataGrid, (prev, next) => {
  return _isEqual(prev?.data, next?.data) &&
    prev?.rowsPerPage == next?.rowsPerPage &&
    _isEqual(prev?.selectedIDs, next?.selectedIDs);
});
