import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Logo from '../../../assets/images/Logo.svg';
import './OndatoSuccess.scss';

const DocumentSuccess = () => {
    return (
        <div className="fv_ondato_success-container">
            <div className="fv_ondato_success-content">
                <div className="fv_ondato_success-header">
                    <img src={Logo} alt="Logo" className="fv_ondato_success-logo mb-5" />
                    <FaCheckCircle className="fv_ondato_success-icon" />
                    <h2 className="fv_ondato_success-title">Document Uploaded Successfully!</h2>
                </div>
                <p className="fv_ondato_success-message">
                    Your document has been securely captured and uploaded.
                </p>
                <p className="fv_ondato_success-message">
                    Our team is now reviewing your document. <br/>
                    You will be notified once the verification is complete. <br />
                </p>
           
            </div>
        </div>
    );
};

export default DocumentSuccess;
