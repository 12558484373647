import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Select, { components } from "react-select";
import { I18nContext } from "../../store/context/i18nContext";
import "./style.css";

const fontSize = 14;

const Menu = (props) => {
  return (
    <Fragment>
      <components.Menu {...props} className="w-100">
        <div className="w-100">
          {props?.selectProps.fetchingData ? (
            <span className="fetching">Fetching data...</span>
          ) : (
            <div>{props?.children}</div>
          )}

          <div
            className={"change-data d-flex justify-content-center w-100 "}
            onClick={props?.selectProps.changeOptionsData}
          >
            {/* <props.selectProps.bottomBarChildren /> */}
          </div>
        </div>
      </components.Menu>
    </Fragment>
  );
};


export const SelectField = (props) => {
  const {
    wrapperStyle,
    data,
    asLabel,
    inline,
    className,
    containerStyle,
    suggestion,
    innerContainerStyle,
    isDisabled,
    onClickLeft,
    LeftComponent,
    placeholder,
    label,
    noLabel,
    showBottomBar,
    desc,
    // bottomBarChildren,
    changeOptionsData,
    renderFunc,
    showClear,
    onMenuScrollToBottom,
    plain,
    onInputChange,
    required,
    RightComponent,
    onClickRight,
    showErr,
    error,
    flexible,
    AddEntityComponent,
    onChange = () => { },
    style,
    innerContainerClassName,
    containerClassName,
    onBlur,
    inputValue,
    inputGroup,
    outline,
    InputGroupChild,
    filterOption,
    dropDownMenuWidth,
    formatOptionLabel,
    labelStyle,
    menuPlacement = "auto",
    defaultValue="",
  } = props;

  const { t } = useContext(I18nContext);
  const [showAddComponent, setShowAddComponent] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = useState(
    data?.find((d) => (d?.value ?? "")?.toString() === (props?.value ?? " ")?.toString()) ?? ""
  );

  const selectRef = useRef(null);


  // useEffect(() => {
  //   if (defaultValue && (!props?.value || props?.value == undefined || props?.value == null ) ) {
  //     let selected = data?.find((d) => d.value === defaultValue || d === defaultValue);
  //     if (selected) {
  //       setValue(selected);
  //       onChange(selected);
  //     }
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    if (props.shouldFocus && selectRef.current && data?.length) {
      setTimeout(() => {
        selectRef.current.focus();
        setMenuOpen(true);
      }, 0); 
    }
  }, [props.shouldFocus, data?.length]);
  
  useEffect(() => {
    if (props?.value !== value?.value && data?.length) {
      if ((props?.value || props?.value == 0)) {
        let selected = data?.find((d) => d.value === props?.value || d === props?.value);
        if (selected) {
          setValue(selected);
        }
      }
      else setValue("")
    }
  }, [props?.value, data]);

  useEffect(() => {
    if (props?.value !== value?.value && data?.length) {
      if (!value) {
        (value == 0) ? onChange(0) : onChange("")
      } else onChange(value);
    }
  }, [value]);

  if (asLabel) {
    return <div>{value.label}</div>;
  }
  return (
    <React.Fragment>
 
      <div
        style={{
          ...wrapperStyle,
          ...style,
        }}
        className={`${inline ? "" : "selectfield__wrapper "} ${className}`}
      >
  
        { !noLabel && !(inline || outline || plain) ? (
          <div
            style={{  fontSize: 16, margin: "0px 0px 5px 5px", fontWeight: "500", display: "block", ...labelStyle }}
          >
            {(label)}
            {required && <font color="red">*</font>}
          </div>
        ) : <div style={{marginTop : inline ? "0px" : "0px"}} ></div>}



        <div
          style={containerStyle}
          className={
            ` ${!(plain || outline || inline) ? "d-flex justify-content-center align-items-center " : " "}      selectfield__container ` +
            (plain && !onFocus ? "selectfield__plain " : "") +
            containerClassName
          }
        >
   
          {suggestion && (
            <b className="selectfield__suggestion">{suggestion}</b>
          )}
          <div
            id="x1"
            className={
              inline ? " selectfield_inline__innercontainer " :  " selectfield__innercontainer " +  " d-flex  flex-1 " + innerContainerClassName
            }
            style={{
              ...innerContainerStyle,
              backgroundColor: isDisabled ? "hsl(0, 0%, 95%)" : "",
            }}
          >
            {LeftComponent && (
              <div
                id="x2"
                onClick={(e) =>
                  onClickLeft ? onClickLeft(e) : null
                }
                className={
                  onClickLeft
                    ? "component_pointer Selectfield__leftComponent"
                    : "selectfield__leftComponent"
                }
                style={{ height: inline ? "19px" : "28px" }}
              >
                < LeftComponent />
              </div>
            )}
            <div
              className={InputGroupChild ? "input-group  w-100 " : inline ? "input-group" : "selectfield x1"}
              id="x5"
            >
              {inline && (
                <div
                  style={{ height: "19px", border: "none", fontSize: 11 }}
                  className="input-group-text"
                >
                  {t(label)}
                </div>
              )}
              {InputGroupChild && (
                <div
                  style={{ border: "none", fontSize: 11 }}
                  className="input-group-text grouped-input-select-group px-2  "
                >
                  < InputGroupChild />
                </div>
              )}
              <Select
                formatOptionLabel={formatOptionLabel}
                {...(filterOption && { filterOption: filterOption })}
                components={
                  showBottomBar
                    ? value
                      ? {
                        IndicatorSeparator: () => null,
                        components,
                        Menu,
                        DropdownIndicator: () => null,
                      }
                      : { IndicatorSeparator: () => null, Menu }
                    : value
                      ? {
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }
                      : { IndicatorSeparator: () => null }
                }
                // bottomBarChildren={bottomBarChildren}
                changeOptionsData={changeOptionsData}
                optionComponent={renderFunc}
                menuPlacement={"bottom"}
                menuPosition={'auto'}
                {...(props?.shouldFocus && { menuIsOpen: menuOpen })}
                onMenuScrollToBottom={onMenuScrollToBottom}
                onInputChange={onInputChange}
                setValue={inputValue}

                value={value}
                styles={
                  InputGroupChild ?
                    stylesForInputGroupChild :
                    inline ?
                      stylesForInline :
                      inputGroup ?
                        stylesForInputGroup :
                        stylesForMain}
                ref={selectRef}
                isClearable={showClear && !plain && !inline}
                onFocus={() => {
                  setOnFocus(true);
                  setMenuOpen(true)
                  if (props?.onFocus) props?.onFocus();
                }}
                onBlur={() => {
                  setMenuOpen(false);
                  setOnFocus(false);
                  if (onBlur) onBlur();
                }}
                options={data}
                onChange={(v) => {

                  if (props?.shouldFocus) {
                
                    setMenuOpen(false);
                    if (selectRef.current) {
                      selectRef.current.blur();
                    }
                  }
                  (v ? setValue(v) : setValue(""))
                }
                }
                className={
                  InputGroupChild ? "form-control px-0 py-0 border-0" :
                    !inline
                      ? "selectfield__select"
                      : "form-control px-0 py-0 rounded-right-1"
                }
                placeholder={
                  noLabel || onFocus || plain || inline || !(inline || outline || plain)
                    ? t(placeholder)
                    : ""
                }
                isDisabled={isDisabled || false}
              />
              {!plain && !noLabel && !inline && (inline || outline || plain) && (
                <label
                  onClick={() => (!onFocus ? selectRef.current.focus() : null)}
                  style={
                    isDisabled
                      ? { backgroundColor: "hsl(0, 0%, 95%)" }
                      : {}
                  }
                  className={
                    value || onFocus
                      ? "selectfield__label px-2 selectfield__notempty_input"
                      : "selectfield__label px-2"
                  }
                >
                  {t(label)}
                  {required && <font color="red">*</font>}
                </label>
              )}
            </div>
            {RightComponent && !inline && (
              <div
                id="x6"
                onClick={(e) =>
                  onClickRight ? onClickRight(e) : null
                }
                style={{ position: "relative", height: inline ? "19px" : "28px" }}
                className={
                  onClickRight
                    ? "component_pointer Selectfield__rightComponent"
                    : "selectfield__rightComponent"
                }
              >
                < RightComponent />
              </div>
            )}
          </div>
       
        </div>
        {desc ? <em className="inputfield__desc d-block">
          {desc ? desc : ""}
        </em> : null}

        {error && showErr && (
          <em className="selectfield__error">{error}</em>
        )}
        {flexible && (
          <button onClick={() => setShowAddComponent(true)}>Add Entity</button>
        )}
      </div>
      {showAddComponent && flexible && AddEntityComponent && (
        <section
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "white",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "1500",
          }}
        >
          < AddEntityComponent setShowAddComponent={setShowAddComponent} />
        </section>
      )}
    </React.Fragment>
  );
};
const stylesForMain = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    height: 24,
    maxHeight: 24,
    minHeight: 24,
    backgroundColor: "transparent !important"
  }),
  singleValue: (base) => ({ ...base, backgroundColor: "transparent !important" }),
  indicatorsContainer: (base) => ({ ...base, height: 22 }),
  valueContainer: (base) => ({ ...base, padding: 0, paddingLeft: "calc(1rem - 3px)", alignItems: "start", backgroundColor: "transparent !important" }),
  placeholder: (base) => ({ ...base, paddingLeft: 0, backgroundColor: "transparent !important" }),
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
  }),
};

const stylesForInputGroup = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    height: 24,
    maxHeight: 24,
    minHeight: 24,
    backgroundColor: "transparent !important",
  }),
  singleValue: (base) => ({ ...base, backgroundColor: "transparent !important", }),
  indicatorsContainer: (base) => ({ ...base, height: 22, backgroundColor: "transparent !important", }),
  valueContainer: (base) => ({ ...base, padding: 0, paddingLeft: "0px", 
    backgroundColor: "transparent !important"  ,
   alignItems : "start"}),
  placeholder: (base) => ({ ...base, paddingLeft: 0, backgroundColor: "transparent !important", }),
  menu: (provided, state) => ({
    ...provided,
    width: "130%",
    minWidth: 'max-content',
    color: state.selectProps.menuColor,
  }),
};

const stylesForInline = {
  control: (base) => ({
    ...base,
    border: "none",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: "none",
    minHeight: "18px",
    height: "18px",
    // width:'100%'
  }),
  container: (base) => ({
    ...base,
    border: "none",
  }),
  placeholder: (base) => ({ ...base, fontSize: 12 }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "18px",
    minWidth: "40px",
    padding: "0 6px",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    // height: "10px",
    padding: 0,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "18px",
  }),
};


const stylesForInputGroupChild = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    height: 27,
    maxHeight: 27,
    minHeight: 27,
    fontSize
  }),
  groupHeading: (base) => ({
    ...base,
    color: '#808080',
    backgroundColor: "#EBEBEB",
    padding: "10px",
    margin: "0px",
    fontSize
  }),
  singleValue: (base) => ({ ...base, fontSize }),
  indicatorsContainer: (base) => ({ ...base, height: 22, paddingTop: 10, }),
  valueContainer: (base) => ({
    ...base,
    padding: 6, paddingLeft: "calc(1rem - 3px)", fontSize,
    backgroundColor: "transparent !important",
    alignItems: "start"
  }),
  placeholder: (base) => ({ ...base, paddingLeft: 0 }),
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
  }),
};
